import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { TableBody, TableCell, TableRow } from '@/components/ui/Table';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { usePaymentsProofsOfRevenueTableHeaders } from '@/components/platform/Payment/PaymentsProofsOfRevenueTable/PaymentsProofsOfRevenueTable.hooks/usePaymentsProofsOfRevenueTableHeaders';
import { getLocaleDateStringDeprecated } from '@/lib/helpers/getLocaleDateStringDeprecated';
import { ProofOfRevenueStatusBadge } from '@/components/platform/Payment/ProofOfRevenueStatusBadge';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { getProofOfRevenuePeriodTranslate } from '@/components/platform/Payment/PaymentsPageContent/utils';
import { type ProofOfRevenueBaseFragment } from '@/controllers/proofOfRevenue/graphql/generated/ProofOfRevenueBase.fragment.generated';
import { type ProofOfRevenueDocumentsFragment } from '@/controllers/user/graphql/generated/ProofOfRevenueDocuments.fragment.generated';
import styles from './PaymentsProofsOfRevenueTable.module.scss';

type Props = {
  proofsOfRevenue: (
    ProofOfRevenueBaseFragment & ProofOfRevenueDocumentsFragment
  )[];
};

export const PaymentsProofsOfRevenueTableBody: FC<Props> = ({
  proofsOfRevenue,
}) => {
  const { t } = useTranslation([I18N_CODES.payment, I18N_CODES.common]);
  const { language } = useSubDomainContext();

  const headers = usePaymentsProofsOfRevenueTableHeaders();

  return (
    <TableBody>
      {proofsOfRevenue
        .map((proofOfRevenue) => {
          const {
            id, proofOfRevenuePeriodData, status, createdAt, errorCode,
          } = proofOfRevenue;

          const {
            periodNumber,
            periodName,
            periodYear,
          } = proofOfRevenuePeriodData;

          const periodData = getProofOfRevenuePeriodTranslate({
            periodName,
            periodNumber,
            periodYear,
            t,
            language,
          });

          return (
            <TableRow key={id}>
              <TableCell
                data-title={headers.date.title}
                className="position-relative"
              >
                <p className={typography.platformTextMain}>
                  {getLocaleDateStringDeprecated(
                    language,
                    Number(createdAt),
                  )}
                </p>
              </TableCell>

              <TableCell data-title={headers.period.title}>
                <p className={typography.platformTextMain} data-qa="por-files-period-info">
                  {periodData}
                </p>
              </TableCell>

              <TableCell data-title={headers.files.title}>
                {proofOfRevenue.documents.map((document) => (
                  <p
                    key={document.type}
                    className={cn(typography.platformTextMain, styles.documentLink, 'mb-4', 'medium-mb-0')}
                    dangerouslySetInnerHTML={{
                      __html: t(`${I18N_CODES.payment}:proof_of_revenue_document_${document.type}`, {
                        link: document.file.url,
                      }),
                    }}
                  />
                ))}
              </TableCell>

              <TableCell data-title={headers.status.title}>
                <ProofOfRevenueStatusBadge
                  status={status}
                  errorCode={errorCode}
                />
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
