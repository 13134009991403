import { useMemo } from 'react';
import {
  type ActiveProfessionsListBaseInfoQueryHookResult,
  useActiveProfessionsListBaseInfoQuery,
} from '@/controllers/profession/generated/activeProfessionsListBaseInfo.query.generated';
import { type ProfessionListBaseInfoFragment } from '@/controllers/profession/generated/professionListBaseInfo.fragment.generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { excludeEntitiesFromList } from '@/controllers/courses/courses.utils/excludeEntitiesFromList';

type Output = [
  ProfessionListBaseInfoFragment[],
  ActiveProfessionsListBaseInfoQueryHookResult,
];

type HookArgs = {
  skip?: boolean;
};

export const useActiveProfessionsListBaseInfo = (args: HookArgs): Output => {
  const { skip } = args;
  const { subDomain, language } = useSubDomainContext();

  const queryResult = useActiveProfessionsListBaseInfoQuery({
    variables: {
      domain: subDomain,
      lang: language,
    },
    skip,
  });

  const content = useMemo(
    () => {
      const result = queryResult.data?.professionsListWithCourses ?? [];

      return excludeEntitiesFromList(result);
    },
    [queryResult.data?.professionsListWithCourses],
  );

  return [content, queryResult];
};
