import React, { type FC } from 'react';
import styles
  from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown/HeaderCoursesDropdown.module.scss';
import { ActiveLink } from '@/components/common/ActiveLink';
import { ROUTES } from '@/controllers/router/router.contants';
import { Button } from '@/components/ui/Button';

interface Props {
  name: string;
  slug: string;
}

export const DropdownProfessionsItem: FC<Props> = (props) => {
  const {
    name,
    slug,
  } = props;

  return (
    <div className={styles.coursesListItem}>
      <ActiveLink
        href={ROUTES.courses(slug)}
        passHref
        exact
      >
        <Button
          size={Button.size.Large}
          mode={Button.mode.TransparentLight}
          text={name}
          title={name}
          className={styles.courseLink}
          hasFullWidth
        />
      </ActiveLink>
    </div>
  );
};
