import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import styles from './CourseFeatureItem.module.scss';

interface Props {
  Icon: FCIcon;
  text: string;
  size: 'LARGE' | 'SMALL';
}
export const CourseFeatureItem: FC<Props> = (props) => {
  const { Icon, text, size } = props;

  return (
    <div className={cn(styles.feature, {
      [styles.featureLarge]: size === 'LARGE',
      [styles.featureSmall]: size === 'SMALL',
    })}
    >
      <Icon className={styles.featureIcon} />

      <p className={styles.featureText}>
        {text}
      </p>
    </div>
  );
};
