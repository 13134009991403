import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment-timezone';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useUpdateUserTimeZoneMutation } from '@/controllers/userTimezone/graphql/generated/updateUserTimezone.mutation.generated';
import { useLocalStorage, useSessionStorage } from '@/hooks/useLocalStorage';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';
import { ManagerTimeZoneModal } from '@/components/common/ManagerUserTimezone/components/ManagerTimeZoneModal';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { Features } from '@/controllers/features/features.constants';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { timeZoneMapperToValidValues } from '@/components/common/ManagerUserTimezone/helpers/timeZoneMapperToValidValues';
import { AuthUserDocument } from '@/controllers/user/graphql/generated/authUser.query.generated';

export const ManagerUserTimeZone: FC = () => {
  const [authUser] = useAuthUser({ ssr: false });
  const feature = useFeatures();
  const [
    isEnteredUserTimezone,
    setIsEnteredUserTimezone,
  ] = useSessionStorage<boolean>(
    SESSION_STORAGE_KEYS.isEnteredUserTimezone,
    false,
  );
  const [
    systemTimeZone,
    setSystemTimeZone,
  ] = useLocalStorage<string | null>(
    LOCAL_STORAGE_KEYS.systemTimeZone,
    null,
  );

  const timeZone = useMemo(
    () => timeZoneMapperToValidValues(
      moment.tz.guess(),
    ),
    [],
  );

  const [mutation, { loading }] = useUpdateUserTimeZoneMutation({
    refetchQueries: [{
      query: AuthUserDocument,
    }],
    onCompleted() {
      setIsEnteredUserTimezone(true);
    },
    onError() {
      setSystemTimeZone(timeZone);
    },
  });

  const updateTimeZone = useCallback(() => mutation({
    variables: {
      timezone: timeZone,
    },
  }), [timeZone, mutation]);

  useEffect(() => {
    if (
      !authUser
      || authUser?.timezone
      || isEnteredUserTimezone
    ) {
      return;
    }

    if (!timeZone) {
      return;
    }

    updateTimeZone();
  }, [authUser, updateTimeZone, isEnteredUserTimezone, timeZone]);

  const isChangeTimezoneModalEnable = feature.isEnabled(
    Features.ChangeTimezoneModal,
  );

  const shouldShowTimeZoneModal = useMemo(
    () => {
      const isChangedTimeZone = isChangeTimezoneModalEnable
        && !!authUser?.timezone
        && timeZone !== systemTimeZone;

      if (!isChangedTimeZone) {
        return false;
      }

      try {
        const newTimezoneCode = moment.tz(timeZone).format('z');
        const oldTimezoneCode = moment.tz(authUser?.timezone || timeZone).format('z');

        return newTimezoneCode !== oldTimezoneCode;
      } catch {
        return timeZone !== authUser?.timezone;
      }
    },
    [
      authUser?.timezone,
      timeZone,
      systemTimeZone,
      isChangeTimezoneModalEnable,
    ],
  );

  const onRequestClose = useCallback(() => {
    setSystemTimeZone(timeZone);
  }, [timeZone, setSystemTimeZone]);

  if (!shouldShowTimeZoneModal) {
    return null;
  }

  return (
    <ManagerTimeZoneModal
      timeZone={timeZone}
      oldTimeZone={authUser?.timezone}
      onClose={onRequestClose}
      updateTimeZone={updateTimeZone}
      isTimeZoneUpdating={loading}
    />
  );
};
