import React, { type DetailedHTMLProps, type FC, type TableHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';
import styles from './TableRow.module.scss';

type TableRowElementProps = DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>;

export type TableRowProps = TableRowElementProps & {
  dataQa?: string;
};

export const TableRow: FC<TableRowProps> = (props) => {
  const {
    children,
    className,
    dataQa = 'table-row',
    ...rest
  } = props;

  const classes = cn(styles.tableRow, className);

  return (
    <tr {...rest} className={classes} data-qa={dataQa}>
      {children}
    </tr>
  );
};
