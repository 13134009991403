import React, { type FC, useEffect } from 'react';
import { cn } from '@/lib/classNames';
import { useClickOutside } from '@/hooks/useClickOutside';
import { Button } from '@/components/ui/Button';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  useRouteChangeCallback,
} from '@/controllers/router/router.hooks/useRouteChangeCallback';
import { useBodyScrollLock } from '@/hooks/useBodyScrollLock';
import { useShouldShowProfessions } from '@/components/hooks/useShouldShowProfessions.hook';
import styles from './HeaderCoursesDropdown.module.scss';
import { DropdownCourses } from './components/DropdownCourses';
import { DropdownProfessions } from './components/DropdownProfessions';

export const HeaderCoursesDropdown: FC = () => {
  const { ref, active, setActive } = useClickOutside(false);
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    setActive: setBodyScrollLock,
  } = useBodyScrollLock(false);

  useRouteChangeCallback(() => {
    setActive(false);
  });

  useEffect(() => {
    setBodyScrollLock(active);
  }, [active, setBodyScrollLock]);

  const shouldShowProfessions = useShouldShowProfessions();

  return (
    <div
      ref={ref}
      className={cn({
        [styles.dropdownOpened]: active,
      })}
    >
      <Button
        mode={Button.mode.Secondary}
        size={Button.size.Large}
        RightIcon={IconChevronDown}
        data-qa="header-courses-dropdown-button"
        className={styles.triggerButton}
        text={t(`${I18N_CODES.common}:header_courses`)}
        title={t(`${I18N_CODES.common}:header_courses`)}
        onClick={() => {
          setActive((value) => !value);
        }}
      />

      <div className={styles.dropdownWrapper} data-qa="header-courses-dropdown">
        {shouldShowProfessions
          ? <DropdownProfessions />
          : <DropdownCourses />}
      </div>
    </div>
  );
};
