import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { ProofOfRevenueStatus } from '@/controllers/graphql/generated';
import styles
  from '@/components/platform/Payment/ProofOfRevenueStatusBadge/ProofOfRevenueStatusBadge.module.scss';
import { useTranslation } from '@/middleware/i18n';
import {
  HorizontalPositionMode,
  ToolTip,
  ToolTipWidthMode,
} from '@/components/ui/ToolTip';
import { IconQuestionRound } from '@/components/ui/icons/IconQuestionRound';

interface Props {
  status: ProofOfRevenueStatus;
  errorCode?: string | null;
}

export const ProofOfRevenueStatusBadge: FC<Props> = ({
  status,
  errorCode,
}) => {
  const { t } = useTranslation([I18N_CODES.payment]);

  const preparedStatus = status.toLowerCase();
  const errorMessage = errorCode
    ? t(`${I18N_CODES.payment}:error_${errorCode}`)
    : '';

  const isErrorMessageVisible = errorMessage && (
    status === ProofOfRevenueStatus.Failed
  || status === ProofOfRevenueStatus.Declined
  );

  return (
    <div className="flex-container align-middle" data-qa="proof-of-revenue-status">
      <small className={cn(
        styles[status],
        styles.proofOfRevenueStatus,
      )}
      >
        { t(`${I18N_CODES.payment}:proof_of_revenue_${preparedStatus}`) }
      </small>

      {isErrorMessageVisible && (
        <ToolTip
          text={errorMessage}
          widthMode={ToolTipWidthMode.Max}
          horizontalPosition={HorizontalPositionMode.LeftSide}
        >
          <IconQuestionRound />
        </ToolTip>
      )}
    </div>
  );
};
