import React, { type FC, useMemo, memo } from 'react';
import { cn } from '@/lib/classNames';
import i18Next, { useTranslation } from '@/middleware/i18n';
import styles from '@/components/platform/Sidebar/Sidebar.module.scss';
import { NotificationCountStyle, SidebarItem } from '@/components/platform/SidebarItem';
import { ROUTES } from '@/controllers/router/router.contants';
import { IconChat } from '@/components/ui/icons/IconChat';
import { I18N_CODES } from '@/lib/constants/general';
import { useChatUnreadsCounterData } from '@/components/platform/Chat/hooks/useChatUnreadsCounterData';

export const ChatsSidebarItem: FC = memo(() => {
  const { t } = useTranslation([I18N_CODES.platformSidebar]);
  const router = i18Next.useRouter();

  const {
    hasAnyUnreadMessages,
    notificationsCount,
  } = useChatUnreadsCounterData();

  const notificationsProps = useMemo(() => ({
    hasNotification: !!hasAnyUnreadMessages,
    notificationsCount,
    notificationsCountStyle: NotificationCountStyle.Highlighted,
  }), [
    notificationsCount,
    hasAnyUnreadMessages,
  ]);

  return (
    <li className={styles.navItem}>
      <SidebarItem
        dataQa='chats-link'
        className={cn({
          [styles.activeSidebarItem]:
            router.pathname.includes(ROUTES.chats.index),
        })}
        icon={(
          <IconChat className={styles.sidebarIcon} />
        )}
        text={t(`${I18N_CODES.platformSidebar}:sidebar.chats_link`)}
        href={ROUTES.chats.index}
        {...notificationsProps}
      />
    </li>
  );
});
