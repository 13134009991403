import React, { type DetailedHTMLProps, type FC, type TableHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';

type TableElementProps = DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableSectionElement>,
  HTMLTableSectionElement
> & {
  dataQa?: string;
};

export type TableBodyProps = TableElementProps;

export const TableBody: FC<TableBodyProps> = (props) => {
  const {
    children,
    className,
    dataQa,
    ...rest
  } = props;
  const classes = cn(className);

  return (
    <tbody
      {...rest}
      className={classes}
      data-qa={dataQa}
    >
      {children}
    </tbody>
  );
};
