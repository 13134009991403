import React, { type FC } from 'react';
import { PaymentsProgressWidgets } from '@/components/platform/Payment/PaymentsPage/Common/Widgets/PaymentsProgressWidgets';
import styles from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHome.module.scss';
import { CareerNotFilledTodo } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/CareerNotFilledTodo';
import { Loader } from '@/components/ui/Loader';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { StudentStatus } from '@/controllers/graphql/generated';
import { GraduatedBlock } from '@/components/platform/Payment/PaymentsDashboard/GraduatedBlock/GraduatedBlock';
import { PaymentsHomeContent } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHomeContent';
import { usePostPaidCourseTodosQuery } from '@/controllers/payments/graphql/generated/getPaymentTodos.query.generated';
import { PostPaidCourseTodosMapper } from '@/components/platform/Payment/PaymentsPage/PostPaidTodosMapper';
import { PaymentsHomeLayout } from '@/components/platform/Payment/PaymentsPage/Common/Layout/PaymentsHomeLayout';

export const PaymentsHome: FC = () => {
  const [authUser] = useAuthUser({ ssr: true });

  const {
    data,
    loading: todosLoading,
  } = usePostPaidCourseTodosQuery({
    fetchPolicy: 'cache-and-network',
  });

  const postpaidCourseTodos = data?.postPaidCourseTodos;

  const isFirstJobSearchTodo = (
    postpaidCourseTodos?.[0]?.jobSearch?.isFirstJobSearch
  );

  if (!authUser) {
    return null;
  }

  if (isFirstJobSearchTodo) {
    return (
      <div className={styles.container}>
        <CareerNotFilledTodo />
      </div>
    );
  }

  const todos = new PostPaidCourseTodosMapper()
    .getNormalizedTodosList(postpaidCourseTodos || []);

  const {
    studentStatus,
    courseMonthPaymentRestricted,
  } = authUser;

  if (studentStatus === StudentStatus.Graduated) {
    return (
      <PaymentsHomeLayout>
        <PaymentsProgressWidgets />

        <div className={styles.graduatedBlock}>
          <GraduatedBlock />
        </div>
      </PaymentsHomeLayout>
    );
  }

  if (todosLoading) {
    return <Loader size={50} loading={todosLoading} />;
  }

  return (
    <PaymentsHomeLayout>
      <PaymentsProgressWidgets />

      <PaymentsHomeContent
        todos={todos}
        courseMonthPaymentRestricted={courseMonthPaymentRestricted}
      />
    </PaymentsHomeLayout>
  );
};
