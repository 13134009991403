import React, { type FC, useCallback, useState } from 'react';
import { type MonthTodo, TodoStatus, PaymentDisabledReason } from '@/components/platform/Payment/PaymentsPage/typedefs';
import { Tag } from '@/components/ui/Tag';
import {
  ICON_BY_STATUS,
  TAG_BY_STATUS,
  TODO_STATUSES_WITH_CONTENT,
} from '@/components/platform/Payment/PaymentsPage/constants';
import { CircleIconWrapper } from '@/components/platform/Learn/CoursePage/CircleIconWrapper';
import { cn } from '@/lib';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import {
  MonthPaymentCardActions,
} from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/MonthPaymentCardActions';
import { ModuleCard } from '@/components/platform/Learn/CoursePage/ModuleCard';
import styles from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/MonthPaymentCard.module.scss';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useCalendarMonthsNames } from '@/components/platform/Schedule/hooks/useCalendarMonthsNames';

interface Props {
  todo: MonthTodo;
  isPaymentRestricted: boolean;
}

export const MonthPaymentCard: FC<Props> = ({
  todo,
  isPaymentRestricted,
}) => {
  const { t } = useTranslation([I18N_CODES.payment, I18N_CODES.schedule]);
  const monthNames = useCalendarMonthsNames();

  const paymentDisabledReason = (
    todo?.payment?.disabledReason
  );

  const isContentVisibilityToggleable = (
    TODO_STATUSES_WITH_CONTENT.includes(todo.status)
    && todo.status !== TodoStatus.Unemployed
  );

  const hasPreviousNotCompletedTodos = (
    paymentDisabledReason === PaymentDisabledReason.PreviousPaymentNotCompleted
  );

  const isContentVisibleByDefault = (
    !hasPreviousNotCompletedTodos
    && todo.status !== TodoStatus.Completed
  );

  const [
    isContentVisible,
    setIsContentVisible,
  ] = useState(isContentVisibleByDefault);

  const toggleIsContentVisible = useCallback(() => {
    setIsContentVisible((prev) => !prev);
  }, []);

  const monthCode = monthNames[todo?.date?.getMonth()];
  const monthYear = todo?.date?.getFullYear();

  const todoTitle = todo.status === TodoStatus.Unemployed
    ? t(`${I18N_CODES.payment}:unemployed_todo.title`)
    : `${monthCode} ${monthYear}`;

  return (
    <ModuleCard
      key={todo?.date?.getTime()}
      className={styles.card}
      titleText={todoTitle}
      renderCardTitleInfo={() => {
        const tagProps = TAG_BY_STATUS[todo.status];

        if (!tagProps) {
          return null;
        }

        const tagText = t(`${I18N_CODES.payment}:${tagProps.text_code}`);

        return (
          <Tag
            text={tagText}
            {...tagProps}
          />
        );
      }}
      renderIcon={() => {
        const Icon = ICON_BY_STATUS[todo.status];

        return (
          <ToolTip
            dataQa='todo-status-tooltip'
            text={t(`${I18N_CODES.payment}:todo_status.${todo.status}`)}
            widthMode={ToolTipWidthMode.Max}
          >
            <CircleIconWrapper>
              <Icon className={cn({
                [styles.iconCompleted]: todo.status === TodoStatus.Completed,
              })}
              />
            </CircleIconWrapper>
          </ToolTip>
        );
      }}
      renderTopicsButton={() => {
        if (!isContentVisibilityToggleable) {
          return null;
        }

        return (
          <Button
            mode={ButtonMode.TransparentLight}
            size={ButtonSize.Small}
            className={cn(styles.toggleButton, {
              [styles.togglerActive]: isContentVisible,
            })}
            onClick={toggleIsContentVisible}
            RightIcon={IconChevronDown}
          />
        );
      }}
      renderCardBottomContent={() => (
        <MonthPaymentCardActions
          todo={todo}
          isContentVisible={isContentVisible}
          isPaymentRestricted={isPaymentRestricted}
        />
      )}
    />
  );
};
