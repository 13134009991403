import React, { type FC } from 'react';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib/classNames';
import { toUTCDate } from '@/lib/helpers/dates/toUTCDate';
import { I18N_CODES } from '@/lib/constants/general';
import {
  TableBody, TableCell, TableRow,
} from '@/components/ui/Table';
import { Loader } from '@/components/ui/Loader';
import { getLocaleDateStringDeprecated } from '@/lib/helpers/getLocaleDateStringDeprecated';
import { capitalize } from '@/lib/helpers/capitalize';
import styles from '@/components/platform/Payment/PaymentsTable/PaymentsTable.module.scss';
import { useTranslation } from '@/middleware/i18n';
import { usePaymentTableHeaders } from '@/components/platform/Payment/PaymentsTable/PaymentTable.hooks/usePaymentTableHeaders';
import { TransactionStatusBadge } from '@/components/platform/Payment/TransactionStatusBadge';
import { typography } from '@/components/ui/typography';
import { PaymentStatus } from '@/controllers/graphql/generated';
import { useUserCourseMonthPayments } from '@/controllers/courseMonthPayments/courseMonthPayments.hooks';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  PAYMENT_STATUS_TO_BADGE_TYPE,
  BadgeType,
} from '@/components/platform/Payment/TransactionStatusBadge/typography';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { ChubbsPlaceholder } from '@/components/ui/ChubsPlaceholder/ChubbsPlaceholder';
import {
  ChubbsPlaceholderBackground,
  ChubbsPlaceholderType,
} from '@/components/ui/ChubsPlaceholder/chubbsPlaceholderType';

interface Props {
  filterStatus?: PaymentStatus;
  columnsCount: number;
}

export const PaymentsTableBody: FC<Props> = ({
  filterStatus,
  columnsCount,
}) => {
  const {
    t,
  } = useTranslation([I18N_CODES.payment, I18N_CODES.common]);
  const { language } = useSubDomainContext();
  const headers = usePaymentTableHeaders();
  const features = useFeatures();

  const canShowPaymentReceipt = features.isEnabled(
    Features.ShowPaymentReceiptLink,
  );

  const [
    userCourseMonthPayments,
  ] = useUserCourseMonthPayments({
    statuses: [
      PaymentStatus.Accepted,
      PaymentStatus.OnReview,
      PaymentStatus.Under_3dSecure,
      PaymentStatus.Declined,
      PaymentStatus.Failed,
    ],
  });

  const monthPayments = filterStatus
    ? userCourseMonthPayments.filter((courseMonthPayment) => {
      const payment = courseMonthPayment.orderItem?.order?.payment;

      return payment?.status === filterStatus;
    })
    : userCourseMonthPayments;

  if (!monthPayments.length) {
    return (
      <td colSpan={columnsCount}>
        <ChubbsPlaceholder
          className={styles.placeholder}
          chubbsType={ChubbsPlaceholderType.Typing}
          textPrimary={t(`${I18N_CODES.payment}:statistics.chubbs_placeholder.title`)}
          backgroundColor={ChubbsPlaceholderBackground.Info}
        />
      </td>
    );
  }

  return (
    <TableBody>
      {monthPayments
        .map((courseMonthPayment) => {
          const {
            id, salary, salaryCurrency,
            salaryDate, amount, amountCurrency,
            orderItem, createdAt, skipped, extraFeeAmount,
            percentageFeeAmount,
          } = courseMonthPayment;

          const monthPaidOffDate = courseMonthPayment.monthPaidOffDate
            ? toUTCDate(new Date(courseMonthPayment.monthPaidOffDate))
            : null;

          const salaryCurrencySign = t(
            `${I18N_CODES.common}:${salaryCurrency?.code}`,
          );

          const amountCurrencySign = t(
            `${I18N_CODES.common}:${amountCurrency?.code}`,
          );

          const payment = orderItem?.order?.payment;

          const paymentBadgeTypeByStatus = payment?.status
            ? PAYMENT_STATUS_TO_BADGE_TYPE[payment.status]
            : BadgeType.Skipped;

          const paymentBadgeType = payment?.refundDate
            ? BadgeType.Refunded
            : paymentBadgeTypeByStatus;

          return (
            <TableRow key={id}>
              <TableCell
                data-title={headers.date.title}
                className="position-relative"
              >
                <NoSSR onSSR={<Loader loading size={15} />}>
                  <p className={typography.platformH4}>
                    {!skipped && `${
                      getLocaleDateStringDeprecated(
                        language,
                        Number(createdAt),
                      )
                    } / ${
                      getLocaleDateStringDeprecated(
                        language,
                        Number(salaryDate),
                      )
                    }`}
                  </p>
                  {monthPaidOffDate && (
                    <p className={typography.platformH4} data-qa='month-for-payment'>
                      {`
                           ${t(`${I18N_CODES.payment}:month_for_prefix`)}
                           ${capitalize(monthPaidOffDate.toLocaleDateString(`${language}`, { month: 'long' }))}
                           ${new Date(monthPaidOffDate).getFullYear()}
                        `}
                    </p>
                  )}
                </NoSSR>
              </TableCell>
              <TableCell data-title={headers.salary.title}>
                {salary && (
                  <p className={typography.platformTextMain}>{`${salaryCurrencySign} ${salary}`}</p>
                )}
              </TableCell>

              <TableCell data-title={headers.amount.title}>
                <div>
                  {amount && (
                    <p className={typography.platformTextMain} data-qa='full-time-payment-amount'>
                      {`${amountCurrencySign} ${amount.toFixed(2)}`}
                    </p>
                  )}
                  {Number(percentageFeeAmount) > 0 && (
                    <p
                      data-qa='payment-fee-amount'
                      className={cn(
                        styles.feeText,
                        typography.platformTextMain,
                      )}
                    >
                      {`+ ${
                        amountCurrencySign
                      } ${
                        percentageFeeAmount?.toFixed(2)
                      } - ${
                        t(`${I18N_CODES.payment}:percentage_fee_amount`)
                      }`}
                    </p>
                  )}
                  {Number(extraFeeAmount) > 0 && (
                    <p
                      data-qa='extra-fee-amount'
                      className={cn(
                        styles.feeText,
                        typography.platformTextMain,
                      )}

                    >
                      {`+ ${
                        amountCurrencySign
                      } ${
                        extraFeeAmount?.toFixed(2)
                      } - ${
                        t(`${I18N_CODES.payment}:extra_fee_amount`)
                      }`}
                    </p>
                  )}
                </div>
              </TableCell>

              {canShowPaymentReceipt && (
                <TableCell data-title={headers.receipt.title}>
                  {payment?.receipt && (
                    <a
                      href={payment.receipt}
                      className={cn(typography.platformTextMain, styles.link)}
                      target='_blank'
                      rel="noreferrer"
                    >
                      {t(`${I18N_CODES.payment}:payment_receipt`)}
                    </a>
                  )}
                </TableCell>
              )}

              <TableCell data-title={headers.status.title}>
                <TransactionStatusBadge
                  badgeType={paymentBadgeType}
                  errorMessage={payment?.errorMessage}
                />
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
