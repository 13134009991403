import React, { type FC, useCallback } from 'react';
import {
  MonthPaymentCardAction,
} from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/MonthPaymentCardAction';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import {
  PostPaidCourseProofOfRevenueStatus,
  type TodoActionProofOfRevenue,
} from '@/components/platform/Payment/PaymentsPage/typedefs';
import { I18N_CODES } from '@/lib/constants/general';
import { getProofOfRevenuePeriodTranslate } from '@/components/platform/Payment/PaymentsPageContent/utils';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { Tag } from '@/components/ui/Tag';
import { POR_STATUS_TO_TAG_PROPS } from '@/components/platform/Payment/PaymentsPage/constants';
import {
  useProofOfRevenueCtaDisabledReason,
} from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/useProofOfRevenueCtaDisabledReason';
import { ToolTip, ToolTipContainerWidthMode, ToolTipWidthMode } from '@/components/ui/ToolTip';

interface Props {
  proofOfRevenue: TodoActionProofOfRevenue;
}

export const UploadDeclarationModule: FC<Props> = ({
  proofOfRevenue,
}) => {
  const { t } = useTranslation([I18N_CODES.payment]);
  const { language } = useSubDomainContext();
  const { push } = useRouter();

  const {
    deadlineStatus,
    daysDiff,
    availableAt,
    descriptionData,
    actionStatus,
    disabledReason,
  } = proofOfRevenue;

  const ctaDisabledReason = useProofOfRevenueCtaDisabledReason({
    disabledReason,
    availableAt: new Date(availableAt ?? new Date()),
  });

  const handleUploadDeclaration = useCallback(() => {
    push(ROUTES.user.uploadProofOfRevenue);
  }, [push]);

  const periodData = getProofOfRevenuePeriodTranslate({
    ...descriptionData,
    t,
    language,
  });

  const description = t(`${I18N_CODES.payment}:fop_proof_of_revenue_subtitle`, {
    periodData,
    periodYear: descriptionData?.periodYear,
  });

  const shouldRenderStatusTag = (
    actionStatus !== PostPaidCourseProofOfRevenueStatus.NotCompleted
    && actionStatus !== PostPaidCourseProofOfRevenueStatus.Accepted
  );

  const renderStatusTag = actionStatus && shouldRenderStatusTag
    ? () => {
      const tagProps = POR_STATUS_TO_TAG_PROPS[actionStatus];

      if (!tagProps) {
        return null;
      }

      return (
        <ToolTip
          text={t(`${I18N_CODES.payment}:${tagProps.tooltip_code}`)}
          widthMode={ToolTipWidthMode.Max}
          containerWidthMode={ToolTipContainerWidthMode.FullContent}
          renderWithPortal
        >
          <Tag
            color={tagProps.color}
            IconElement={tagProps.IconElement}
            text={t(`${I18N_CODES.payment}:${tagProps.code}`)}
          />
        </ToolTip>
      );
    }
    : null;

  return (
    <MonthPaymentCardAction
      title={t(`${I18N_CODES.payment}:actions.declaration_title`)}
      description={description}
      ctaText={t(`${I18N_CODES.payment}:actions.declaration_cta`)}
      ctaDisabledReason={ctaDisabledReason}
      deadlineStatus={deadlineStatus}
      deadlineDaysDiff={Math.abs(daysDiff)}
      handleCTAClick={handleUploadDeclaration}
      renderStatusTag={renderStatusTag}
    />
  );
};
