import React, { type FC, useMemo } from 'react';
import {
  PaymentsWidgetCard,
  WidgetCardIconColor,
} from '@/components/platform/Payment/PaymentsPage/Common/Widgets/PaymentsWidgetCard';
import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { IconList } from '@/components/ui/icons/IconList';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useUserCourseMonthPayments } from '@/controllers/courseMonthPayments/courseMonthPayments.hooks';
import { PaymentStatus } from '@/controllers/graphql/generated';
import styles from '@/components/platform/Payment/PaymentsPage/Common/Widgets/PaymentsProgressWidgets.module.scss';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

export const PaymentsProgressWidgets: FC = () => {
  const { t } = useTranslation([I18N_CODES.payment]);
  const [authUser] = useAuthUser({ ssr: true });
  const [userCourseMonthPayments] = useUserCourseMonthPayments();

  const acceptedPaymentsCount = useMemo(() => {
    const acceptedPayments = userCourseMonthPayments
      .filter((courseMonthPayment) => {
        const payment = courseMonthPayment.orderItem?.order?.payment;

        return (
          payment?.status === PaymentStatus.Accepted
          && !payment?.refundDate
        );
      });

    return acceptedPayments.length;
  }, [userCourseMonthPayments]);

  const paymentsLeft = (authUser?.agreementMonths || 0) - acceptedPaymentsCount;

  return (
    <div className={styles.widgets}>
      <PaymentsWidgetCard
        title={t(`${I18N_CODES.payment}:payments.widget_card.completed_payments`)}
        Icon={IconSuccess}
        iconColor={WidgetCardIconColor.Success}
        number={acceptedPaymentsCount}
      />

      <PaymentsWidgetCard
        title={t(`${I18N_CODES.payment}:payments.widget_card.payments_left`)}
        Icon={IconList}
        iconColor={WidgetCardIconColor.Purple}
        number={paymentsLeft}
      />
    </div>
  );
};
