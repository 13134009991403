import { type TFunction } from 'next-i18next';
import { I18N_CODES } from '@/lib/constants/general';

type GetProofOfRevenuePeriodTranslate = {
  periodName?: string;
  periodNumber?: string;
  periodYear?: number;
  language: string;
} & { t: TFunction };

export const getProofOfRevenuePeriodTranslate = (
  options: GetProofOfRevenuePeriodTranslate,
) => {
  const {
    periodName,
    periodNumber,
    periodYear,
    language,
    t,
  } = options;

  const quarterPeriodTranslate = `${t(`${I18N_CODES.payment}:${periodNumber}_${periodName}`, {
    periodYear,
  })}`;
  const monthlyPeriodTranslate = `${new Date(Number(periodYear), Number(periodNumber))
    .toLocaleDateString(
      language,
      {
        month: 'long',
        year: 'numeric',
      },
    )}`;

  return periodName
    ? quarterPeriodTranslate
    : monthlyPeriodTranslate;
};
