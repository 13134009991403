import React, { type FC, memo, useMemo } from 'react';
import { cn } from '@/lib/classNames';
import { ActiveLink } from '@/components/common/ActiveLink';
import { IconLock } from '@/components/ui/icons/IconLock';
import { MAX_NOTIFICATIONS_COUNT } from './SidebarItem.constants';
import styles from './SidebarItem.module.scss';

export enum NotificationCountStyle {
  Highlighted,
  Common
}

export const notificationStyleClassNames: Record<
  NotificationCountStyle,
  string
> = {
  [NotificationCountStyle.Highlighted]: styles.highlighted,
  [NotificationCountStyle.Common]: styles.common,
};

interface Props {
  icon: JSX.Element;
  text: string;
  href: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => any;
  hasNotification?: boolean;
  notificationsCount?: number;
  notificationsCountStyle?: NotificationCountStyle;
  dataQa?: string;
}

export const SidebarItem: FC<Props> = memo(({
  icon,
  text,
  href,
  disabled,
  className,
  onClick,
  hasNotification,
  notificationsCount,
  notificationsCountStyle = NotificationCountStyle.Highlighted,
  dataQa,
}) => {
  const [
    shouldRenderSimpleNotificationSymbol,
    shouldRenderNotificationWithCount,
  ] = useMemo(() => ([
    hasNotification && !notificationsCount,
    hasNotification && !!notificationsCount,
  ]), [hasNotification, notificationsCount]);

  return (
    <ActiveLink href={href} passHref>
      <a
        data-qa="sidebar-navigation-link"
        title={text}
        href={href}
        className={cn(styles.sidebarItem, className, {
          [styles.disabled]: disabled,
        })}
        onClick={onClick}
      >
        <span
          className={cn(styles.itemIcon, {
            [styles.accent]: shouldRenderSimpleNotificationSymbol,
          })}
        >
          {disabled
            ? <IconLock />
            : icon}

          {shouldRenderNotificationWithCount && (
            <div
              className={cn(
                styles.notificationsCounter,
                notificationStyleClassNames[notificationsCountStyle],
              )}
              data-qa="sidebar-notifications-counter"
            >
              {
                (
                  notificationsCount
                    && notificationsCount > MAX_NOTIFICATIONS_COUNT
                )
                  ? `${MAX_NOTIFICATIONS_COUNT}+`
                  : notificationsCount
              }
            </div>
          )}
        </span>
        <span className={styles.itemText} data-qa={dataQa}>
          {text}
        </span>
      </a>
    </ActiveLink>
  );
});
