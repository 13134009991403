import React, { type FC } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import styles from './WidgetCard.module.scss';

export enum WidgetCardIconColor {
  Success = 'success',
  Purple = 'purple',
}

interface Props {
  Icon: FCIcon;
  title: string;
  number: number;
  iconColor: WidgetCardIconColor;
}

export const PaymentsWidgetCard: FC<Props> = ({
  Icon,
  number,
  title,
  iconColor,
}) => (
  <div className={styles.container} data-qa="payment-widget">
    <div className={cn(styles.iconContainer, styles[`iconContainer--${iconColor}`])}>
      <Icon className={cn(styles.icon, styles[`icon--${iconColor}`])} />
    </div>

    <div>
      <p className={cn(typography.platformTextSmall, styles.title)}>
        {title}
      </p>

      <h1 className={styles.number} data-qa="payments-amount">
        {number}
      </h1>
    </div>
  </div>
);
