import React, { type FC } from 'react';
import styles from '@/components/platform/Payment/PaymentsDashboard/GraduatedBlock/GraduatedBlock.module.scss';
import { IconEmojiTada } from '@/components/ui/IconEmojies/IconEmojiTada';
import { IconEmojiHeartEyes } from '@/components/ui/IconEmojies/IconEmojiHeartEyes';
import { IconEmojiNerdFace } from '@/components/ui/IconEmojies/IconEmojiNerdFace';
import { cn } from '@/lib/classNames';
import { IconEmojiPartyingFace } from '@/components/ui/IconEmojies/IconEmojiPartyingFace';
import { IconEmojiStarStruck } from '@/components/ui/IconEmojies/IconEmojiStarStruck';

export const CirclesWrapper: FC = ({ children }) => (
  <div className={styles.outerCircle}>
    <div className={cn(styles.emoji, 'p-12')}>
      <IconEmojiTada size={24} />
    </div>

    <div className={cn(styles.emoji, 'p-12')}>
      <IconEmojiHeartEyes size={16} />
    </div>

    <div className={cn(styles.emoji, 'p-12')}>
      <IconEmojiPartyingFace size={16} />
    </div>

    <div className={cn(styles.emoji, 'p-8')}>
      <IconEmojiStarStruck size={16} />
    </div>

    <div className={cn(styles.emoji, 'p-8')}>
      <IconEmojiNerdFace size={24} />
    </div>

    <div className={styles.middleCircle}>
      <div className={styles.innerCircle}>
        {children}
      </div>
    </div>
  </div>
);
