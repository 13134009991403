import {
  JobSearchActionType,
  type MonthTodo,
  type PaymentFeeData,
  type ProofOfRevenueDescriptionData,
  type TodoActionPayment,
  TodoStatus,
  type PaymentDisabledReason,
  PostPaidCoursePaymentStatus,
  PostPaidCourseProofOfRevenueStatus,
  type PostPaidCourseTodo,
  type PostPaidCourseTodoJobSearch,
  type PostPaidCourseTodoPayment,
  type PostPaidCourseTodoProofOfRevenue, type ProofOfRevenueDisabledReason,
} from '@/components/platform/Payment/PaymentsPage/typedefs';
import { getDateDiffInDays } from '@/lib/helpers/getDateDiff';
import { DeadlineStatus } from '@/components/platform/Payment/PaymentsPage/constants';

interface GetActionDataProps {
  deadline?: Date | null;
  feeData?: PaymentFeeData | null;
  availableAt?: Date;
  descriptionData?: ProofOfRevenueDescriptionData | null;
  disabledReason?: PaymentDisabledReason | ProofOfRevenueDisabledReason | null;
  isCompleted: boolean;
  status?: PostPaidCourseProofOfRevenueStatus | PostPaidCoursePaymentStatus;
}

interface GetTodoStatusOptions {
  monthTimestamp: string;
  payment?: PostPaidCourseTodoPayment | null;
  proofOfRevenue?: PostPaidCourseTodoProofOfRevenue | null;
  jobSearch?: PostPaidCourseTodoJobSearch | null;
}

const NOT_FOUND_TODO_INDEX = -1;

export class PostPaidCourseTodosMapper {
  getNormalizedTodosList(todos: PostPaidCourseTodo[]): MonthTodo[] {
    const generatedTodos = todos.map(
      (todo) => ({
        date: new Date(Number(todo.monthTimestamp)),
        status: this.getStatus(todo),
        payment: this.getPayment(todo.payment),
        proofOfRevenue: this.getProofOfRevenue(todo.proofOfRevenue),
        jobSearchActions: this.getJobSearchActions(todo.jobSearch),
      }),
    );

    const firstTodoIndex = this.getFirstTodoIndex(generatedTodos);

    const lastTodoIndex = generatedTodos.findIndex(
      (todo) => (
        todo.status === TodoStatus.NotAvailable
        || todo.status === TodoStatus.Unemployed
      ),
    );

    const normalizedLastTodoIndex = lastTodoIndex === NOT_FOUND_TODO_INDEX
      ? generatedTodos.length - 1
      : lastTodoIndex;

    return generatedTodos.slice(firstTodoIndex, normalizedLastTodoIndex + 1); // end index is not included in slice
  }

  private getFirstTodoIndex(
    todos: MonthTodo[],
  ) {
    const inProgressTodoIndex = todos.findIndex(
      (todo) => todo.status === TodoStatus.InProgress,
    );

    const futureTodoIndex = todos.findIndex((todo) => (
      todo.status === TodoStatus.NotAvailable
        || todo.status === TodoStatus.Unemployed
    ));

    if (
      inProgressTodoIndex === NOT_FOUND_TODO_INDEX
      && futureTodoIndex === NOT_FOUND_TODO_INDEX
    ) {
      return 0;
    }

    const currentTodoIndex = inProgressTodoIndex >= 0
      ? inProgressTodoIndex
      : futureTodoIndex;

    return todos?.[currentTodoIndex - 1]
      ? currentTodoIndex - 1 // in order to render prev completed todo
      : currentTodoIndex;
  }

  private getJobSearchActions(
    jobSearch?: PostPaidCourseTodoJobSearch | null,
  ) {
    if (!jobSearch) {
      return undefined;
    }

    return jobSearch.isFirstJobSearch
      ? [JobSearchActionType.FindJob]
      : [JobSearchActionType.ContactSupport, JobSearchActionType.FindJob];
  }

  private getProofOfRevenue(
    proofOfRevenue?: PostPaidCourseTodoProofOfRevenue | null,
  ) {
    if (!proofOfRevenue) {
      return null;
    }

    const {
      deadline,
      collectionStartDate,
      periodData,
      status,
      disabledReason,
    } = proofOfRevenue;

    const isCompleted = (
      status === PostPaidCourseProofOfRevenueStatus.Accepted
    );

    return this.getActionData<
      PostPaidCourseProofOfRevenueStatus,
      ProofOfRevenueDisabledReason
    >({
      deadline,
      status,
      isCompleted,
      disabledReason,
      availableAt: collectionStartDate,
      descriptionData: periodData,
    });
  }

  private getPayment(
    payment?: PostPaidCourseTodoPayment | null,
  ): TodoActionPayment | null {
    if (!payment) {
      return null;
    }

    const {
      deadline,
      collectionStartDate,
      feeData,
      status,
      disabledReason,
    } = payment;

    const feeDataPayload = feeData
      ? {
        extraFeeAmount: feeData.extraFeeAmount,
        percentageFeeAmount: feeData.percentageFeeAmount,
        currency: feeData.currencyCode,
        shouldPayFee: true,
      }
      : undefined;

    const isCompleted = (
      status === PostPaidCoursePaymentStatus.Accepted
      || status === PostPaidCoursePaymentStatus.Skipped
    );

    return this.getActionData<
      PostPaidCoursePaymentStatus,
      PaymentDisabledReason
    >({
      deadline,
      isCompleted,
      disabledReason,
      availableAt: collectionStartDate,
      feeData: feeDataPayload,
      status,
    });
  }

  private getStatus({
    monthTimestamp,
    payment,
    proofOfRevenue,
    jobSearch,
  }: GetTodoStatusOptions): TodoStatus {
    if (jobSearch) {
      return TodoStatus.Unemployed;
    }

    if (new Date(Number(monthTimestamp)) > new Date()) {
      return TodoStatus.NotAvailable;
    }

    const isPaymentCompleted = !payment || (
      payment.status === PostPaidCoursePaymentStatus.Accepted
      || payment.status === PostPaidCoursePaymentStatus.Skipped
    );

    const isProofOfRevenueCompleted = (
      !proofOfRevenue
      || proofOfRevenue?.status === PostPaidCourseProofOfRevenueStatus.Accepted
    );

    const isCompleted = isPaymentCompleted && isProofOfRevenueCompleted;

    return isCompleted
      ? TodoStatus.Completed
      : TodoStatus.InProgress;
  }

  private getActionData<
    ActionStatusType,
    DisabledReasonType,
  >({
    deadline,
    feeData,
    availableAt,
    descriptionData,
    isCompleted,
    disabledReason,
    status,
  }: GetActionDataProps) {
    if (!deadline) {
      return null;
    }

    const daysDiff = getDateDiffInDays(new Date(), new Date(deadline));

    return {
      daysDiff,
      availableAt,
      feeData,
      descriptionData,
      actionStatus: status as ActionStatusType,
      disabledReason: disabledReason as DisabledReasonType,
      deadlineStatus: this.getActionDeadlineStatus(daysDiff, isCompleted),
    };
  }

  private getActionDeadlineStatus(
    daysDiff: number,
    isCompleted: boolean,
  ): DeadlineStatus {
    if (isCompleted) {
      return DeadlineStatus.Completed;
    }

    return daysDiff > 0
      ? DeadlineStatus.InProgress
      : DeadlineStatus.Overdue;
  }
}
