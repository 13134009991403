import { type FC } from 'react';
import { HeaderCtaButtonContainer } from '@/components/landing/LandingHeader/components/HeaderCtaButtonContainer';
import { Error } from '@/components/error/Error';
import { LandingHeader } from '@/components/landing/LandingHeader';
import { HeaderProfileActions } from '@/components/landing/LandingHeader/components/HeaderProfileActions';
import styles from './ErrorModule.module.scss';

interface Props {
  code?: number;
}

export const ErrorModule: FC<Props> = ({ code = 400 }) => (
  <div>
    <LandingHeader>
      <HeaderCtaButtonContainer>
        <HeaderProfileActions />
      </HeaderCtaButtonContainer>
    </LandingHeader>

    <Error
      code={code}
      className={styles.errorBody}
    />
  </div>
);
