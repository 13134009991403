import { type FC } from 'react';
import { Error } from '@/components/error/Error';
import { useLearningRootForRedirect } from '@/components/platform/Study/pages/urlTools';
import styles from './AuthUserErrorModule.module.scss';

interface Props {
  code?: number;
}

export const AuthUserErrorModule: FC<Props> = ({ code = 400 }) => {
  const learningRoot = useLearningRootForRedirect();

  return (
    <Error
      code={code}
      className={styles.errorBody}
      redirectUrl={learningRoot}
    />
  );
};
