import React, { type FC } from 'react';
import styles
  from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown/HeaderCoursesDropdown.module.scss';
import {
  useActiveProfessionsListBaseInfo,
} from '@/controllers/profession/profession.hooks/useActiveProfessionsListBaseInfo';
import { DropdownProfessionsList } from '../DropdownProfessionsList';
import { ProfessionsListScreen } from '../../../HeaderMobileMenu/components/ProfessionsListScreen/ProfessionsListScreen';

export const DropdownProfessions: FC = () => {
  const [professionsList] = useActiveProfessionsListBaseInfo({});

  return (
    <>
      <div className='hide-for-large'>
        <ProfessionsListScreen />
      </div>

      <div className='show-for-large'>
        <div className={styles.dropdownItem} data-qa="dropdown-profession-item">
          <DropdownProfessionsList
            items={professionsList}
          />
        </div>
      </div>
    </>
  );
};
