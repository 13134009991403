import { type DeadlineStatus } from '@/components/platform/Payment/PaymentsPage/constants';

export enum TodoStatus {
  InProgress = 'in_progress',
  PrevTodoRequired = 'prev_todo_required',
  NotAvailable = 'not_available',
  Completed = 'completed',
  Unemployed = 'unemployed',
}

export interface MonthTodo {
  date: Date;
  status: TodoStatus;
  payment: TodoActionPayment | null;
  proofOfRevenue: TodoActionProofOfRevenue | null;
  jobSearchActions?: JobSearchActionType[];
}

export interface PaymentFeeData {
  extraFeeAmount: number;
  percentageFeeAmount: number;
  currency: string;
  shouldPayFee: boolean;
}

export interface TodoActionPayment {
  deadlineStatus: DeadlineStatus;
  daysDiff: number;
  availableAt?: Date;
  feeData?: PaymentFeeData | null;
  disabledReason?: PaymentDisabledReason | null;
  actionStatus?: PostPaidCoursePaymentStatus;
}

export interface TodoActionProofOfRevenue {
  deadlineStatus: DeadlineStatus;
  daysDiff: number;
  availableAt?: Date | null;
  descriptionData?: ProofOfRevenueDescriptionData | null;
  actionStatus?: PostPaidCourseProofOfRevenueStatus;
  disabledReason?: ProofOfRevenueDisabledReason | null;
}

export type ProofOfRevenueDescriptionData = {
  periodName: string;
  periodNumber: string;
  periodYear: number;
};

export enum JobSearchActionType {
  ContactSupport = 'contact_support',
  FindJob = 'find_job',
}

export interface CompletedTodoActionPayload {
  completedAt: Date;
  monthTimeStamp: Date;
}

export interface CompletedPaymentActionPayload
  extends CompletedTodoActionPayload {
    amount?: number;
}

export interface PostPaidCourseTodo {
  monthTimestamp: string;
  payment?: PostPaidCourseTodoPayment | null;
  proofOfRevenue?: PostPaidCourseTodoProofOfRevenue | null;
  jobSearch?: PostPaidCourseTodoJobSearch | null;
}

export interface PostPaidCourseTodoJobSearch {
  isFirstJobSearch: boolean;
}

export interface PostPaidCourseTodoPayment {
  deadline: Date;
  collectionStartDate: Date;
  status: PostPaidCoursePaymentStatus;
  isSkippable: boolean;
  feeData?: PostPaidCourseTodoPaymentFeeData | null;
  disabledReason?: PaymentDisabledReason | ProofOfRevenueDisabledReason | null;
}

export enum PaymentDisabledReason {
  TooEarlyToPay = 'TooEarlyToPay',
  OverdueProofOfRevenue = 'OverdueProofOfRevenue',
  PendingProofOfRevenue = 'PendingProofOfRevenue',
  IsManuallyRestricted = 'IsManuallyRestricted',
  PreviousPaymentNotCompleted = 'PreviousPaymentNotCompleted',
}

export enum ProofOfRevenueDisabledReason {
  TooEarlyToUpload = 'TooEarlyToUpload',
  PreviousProofOfRevenueRequired = 'PreviousProofOfRevenueRequired',
  PendingProofOfRevenue = 'PendingProofOfRevenue',
}

export enum PostPaidCoursePaymentStatus {
  Accepted = 'Accepted',
  Skipped = 'Skipped',
  NotCompleted = 'NotCompleted',
}

export enum PostPaidCourseProofOfRevenueStatus {
  Accepted = 'Accepted',
  Uploaded = 'Uploaded',
  NotCompleted = 'NotCompleted',
  OnReview = 'OnReview',
  Declined = 'Declined',
  Failed = 'Failed',
}

export interface PostPaidCourseTodoProofOfRevenue {
  deadline: Date;
  collectionStartDate: Date;
  disabledReason?: ProofOfRevenueDisabledReason | null;
  periodData: ProofOfRevenueDescriptionData;
  status: PostPaidCourseProofOfRevenueStatus;
}

export interface PostPaidCourseTodoPaymentFeeData {
  extraFeeAmount: number;
  percentageFeeAmount: number;
  currencyCode: string;
}
