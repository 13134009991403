import React, { type FC } from 'react';
import { IconPercent } from '@/components/ui/icons/IconPercent';
import { IconClockTime } from '@/components/ui/icons/IconClockTime';
import { IconFilter } from '@/components/ui/icons/IconFilter';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib/classNames';
import styles from './CourseFeaturesList.module.scss';
import { CourseFeatureItem } from './CourseFeatureItem';

interface Props {
  size: 'LARGE' | 'SMALL';
  className?: string;
}

export const FullTimeCourseFeaturesList: FC<Props> = (props) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const { size, className } = props;

  return (
    <ul className={cn(styles.list, className)}>
      <li className={styles.listItem} data-qa="free-until-employed">
        <CourseFeatureItem
          size={size}
          Icon={IconPercent}
          text={t(`${I18N_CODES.common}:course_feature_free_until_employed`)}
        />
      </li>

      <li className={styles.listItem} data-qa="fulltime-schedule">
        <CourseFeatureItem
          size={size}
          Icon={IconClockTime}
          text={t(`${I18N_CODES.common}:course_feature_fulltime_schedule`)}
        />
      </li>

      <li className={styles.listItem} data-qa="feature-selection">
        <CourseFeatureItem
          size={size}
          Icon={IconFilter}
          text={t(`${I18N_CODES.common}:course_feature_selection`)}
        />
      </li>
    </ul>
  );
};
