import React, { type FC, memo, useCallback } from 'react';
import { Button } from '@/components/ui/Button';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import styles from './HeaderCtaButtonContainer.module.scss';

interface Props {
  href?: string;
}

export const HeaderCtaButtonContainer: FC<Props> = memo((props) => {
  const ctaContext = useCtaContext();

  const {
    children,
    href = ctaContext.buildHref({
      source: 'landing-header',
    }),
  } = props;

  const handleOnClick = useCallback(() => {
    ctaContext.callback({
      type: 'header-cta',
    });
  }, [ctaContext]);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.childrenWrapper}>
        {children}
      </div>

      <div className={styles.ctaWrapper}>
        <Button
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          href={href}
          text={ctaContext.text}
          hasFullWidth
          data-role="desktop-cta"
          onClick={handleOnClick}
          dataQa={`${ctaContext.text}-button`}
        />
      </div>
    </div>
  );
});
