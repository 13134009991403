import React, { type FC } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { ProgressBarHorizontal, ProgressBarMode } from '@/components/platform/common/ProgressBarHorizontal';
import { colorTokens } from '@/components/ui/colorTokens';
import styles from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/PaymentMonthProgressBar.module.scss';

interface Props {
  stepsCount: number;
  stepsCompleted: number;
}

export const PaymentMonthProgressBar: FC<Props> = (({
  stepsCompleted,
  stepsCount,
}) => (
  <div className={cn(styles.container, 'mb-24')} data-qa='payment-progress-bar'>
    <h2 className={typography.platformH2}>
      {`${stepsCompleted}/${stepsCount}`}
    </h2>

    <div className={styles.progressBar}>
      <ProgressBarHorizontal
        maxValue={stepsCount}
        progress={stepsCompleted}
        mode={ProgressBarMode.Dashed}
        pathColor={colorTokens['fill-inverse']}
        trailColor={colorTokens['bg-neutral-quaternary']}
      />
    </div>
  </div>
));
