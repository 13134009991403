import React, { Fragment, type ReactElement } from 'react';
import { cn } from '@/lib/classNames';
import { type TabData, type TabRenderProps } from '@/components/platform/Tabs/Tabs.typedefs';
import styles from './SegmentedPickers.module.scss';

interface Props<T extends TabData> {
  activeTabId: any;
  setActiveTab?: (id: any) => void;
  tabsData: T[];
  renderTab?: (tabData: TabRenderProps & T) => ReactElement;
  fullWidth?: boolean;
  dataQa?: string;
  className?: string;
}

type ComponentType = <T extends TabData>(props: Props<T>) => ReactElement;

export const SegmentedPickers: ComponentType = (props) => {
  const {
    tabsData,
    dataQa,
    activeTabId,
    renderTab = ({ title }: TabData) => title,
    fullWidth = false,
    className,
  } = props;

  return (
    <div
      data-qa={dataQa}
      className={cn(styles.container, 'flex-container', {
        [styles['container--fullWidth']]: fullWidth,
      }, className)}
    >
      {tabsData.map((tabData, index) => {
        const { id } = tabData;

        const isActive = activeTabId === id;

        return (
          <Fragment key={id}>
            {
              renderTab({
                ...tabData,
                isActive,
                index,
                className: cn(styles.tab, {
                  [styles.activeTab]: isActive,
                  [styles['tab--fullWidth']]: fullWidth,
                }),
              })
            }
          </Fragment>
        );
      })}
    </div>
  );
};
