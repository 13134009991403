import {
  forwardRef,
  useEffect,
  useRef,
  type ReactNode,
} from 'react';
import { cn } from '@/lib/classNames';
import { useLearnQueryParams } from '@/components/platform/Learn/hooks/useLearnQueryParams';
import {
  GAP_BETWEEN_MODULES,
  LEARNER_HEADER_HEIGHT,
} from '@/components/platform/TestTasks/constants';
import { getCoordinatesRelatedToPage } from '@/lib';
import { PLATFORM_HEADER_HEIGHT } from '@/lib/constants/sizes';
import { useIsMobileWidth } from '@/hooks/useIsMobileWidth';
import { MODULE_CARD_DATA_QA } from '@/components/platform/Learn/CoursePageContent/constants';
import { nullFunction } from '@/lib/helpers/functional';
import { useStudyRouteParams } from '@/components/platform/Study/pages/urlTools';
import styles from './ModuleCard.module.scss';

interface Props {
  id?: string;
  className?: string;
  renderIcon?: () => ReactNode;
  titleText?: string;
  renderCardTitleInfo?: () => ReactNode | null;
  renderDescription?: () => ReactNode | null;
  renderTopicsButton?: () => ReactNode | null;
  renderCardBottomContent?: () => ReactNode | null;
  renderDeadline?: () => ReactNode | null;
  renderAlert?: () => ReactNode | null;
  titleDataQa?: string;
}

export const ModuleCard = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      id,
      className,
      renderIcon = nullFunction,
      titleText,
      renderCardTitleInfo = nullFunction,
      renderDescription = nullFunction,
      renderTopicsButton = nullFunction,
      renderCardBottomContent = nullFunction,
      renderDeadline = nullFunction,
      renderAlert = nullFunction,
      titleDataQa,
    } = props;

    const selfRef = useRef<HTMLDivElement>(null);
    const {
      module,
    } = useLearnQueryParams();
    const {
      moduleSlug: moduleSlugStudy,
    } = useStudyRouteParams();

    const moduleSlug = moduleSlugStudy || module;

    const isMobileWidth = useIsMobileWidth();

    useEffect(() => {
      if (moduleSlug === id && selfRef.current) {
        const offset = isMobileWidth
          ? PLATFORM_HEADER_HEIGHT
          : PLATFORM_HEADER_HEIGHT + LEARNER_HEADER_HEIGHT;

        const { top } = getCoordinatesRelatedToPage(selfRef.current);

        window.scrollTo({
          top: top - offset - (GAP_BETWEEN_MODULES / 2),
          behavior: 'smooth',
        });
      }
    }, [
      isMobileWidth,
      moduleSlug,
      id,
    ]);

    const description = renderDescription();

    return (
      <div
        data-qa={MODULE_CARD_DATA_QA.MODULE_CARD}
        className={cn(
          styles.moduleCardWrapper,
          className,
        )}
        id={id}
        ref={(el) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selfRef.current = el;

          if (ref) {
            if (typeof ref === 'function') {
              ref(el);
            } else {
              // eslint-disable-next-line no-param-reassign
              ref.current = el;
            }
          }
        }}
      >
        <div
          className={cn(styles.cardHeader, {
            [styles.hasDescription]: Boolean(description),
          })}
          data-qa='module-card-header'
        >

          <div className={cn(styles.moduleCardIcon, {
            [styles.moduleCardIconStretch]: Boolean(description),
          })}
          >
            {renderIcon()}
          </div>

          <h2 data-qa={titleDataQa} className={styles.title}>
            {titleText}
          </h2>

          <div className={styles.cardTitleInfo} data-qa='module-card-title-info'>
            {renderCardTitleInfo()}
          </div>

          <div className={styles.courseDropdown} data-qa='course-dropdown-button'>
            {renderTopicsButton()}
          </div>

          <div className={styles.description}>
            {description}
          </div>
        </div>

        <div className={cn(styles.bottomContent, 'mt-16')}>
          {renderAlert()}
        </div>

        <div className={cn(styles.bottomContent, 'mt-16')}>
          {renderCardBottomContent()}
        </div>

        <div className={cn(styles.bottomContent, 'mt-16')}>
          {renderDeadline()}
        </div>
      </div>
    );
  },
);
