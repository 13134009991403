import React, { type FC } from 'react';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import styles from './HeaderNavigation.module.scss';

export const HeaderNavigationSinglePage: FC = () => {
  const { t } = useTranslation([I18N_CODES.common]);

  return (
    <nav
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      <ul
        itemProp="about"
        itemScope
        itemType="http://schema.org/ItemList"
        className="flex-container"
      >
        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          className={styles.showOnXLarge}
        >
          <Button
            href={`#${COURSE_BLOCKS.aboutPlatform}`}
            text='Why Mate academy'
            title='Why Mate academy'
            size={Button.size.Large}
            mode={Button.mode.TransparentLight}
            className={styles.linkButton}
          />
        </li>

        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          className={styles.showOnXLarge}
        >
          <Button
            href={`#${COURSE_BLOCKS.price}`}
            text={t(`${I18N_CODES.common}:price_page_link`)}
            title={t(`${I18N_CODES.common}:price_page_link`)}
            size={Button.size.Large}
            mode={Button.mode.TransparentLight}
            className={styles.linkButton}
          />
        </li>

        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          className={styles.showOnXLarge}
        >
          <Button
            href={`#${COURSE_BLOCKS.program}`}
            text="Course roadmap"
            title="Course roadmap"
            size={Button.size.Large}
            mode={Button.mode.TransparentLight}
            className={styles.linkButton}
          />
        </li>

        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          className={styles.showOnXLarge}
        >
          <Button
            href={`#${COURSE_BLOCKS.reviews}`}
            text="Reviews"
            title="Reviews"
            size={Button.size.Large}
            mode={Button.mode.TransparentLight}
            className={styles.linkButton}
          />
        </li>

        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          className={styles.showOnXLarge}
        >
          <Button
            href={`#${COURSE_BLOCKS.faq}`}
            text="FAQ"
            title="FAQ"
            size={Button.size.Large}
            mode={Button.mode.TransparentLight}
            className={styles.linkButton}
          />
        </li>
      </ul>
    </nav>
  );
};
