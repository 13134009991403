import React, { type FC, useEffect } from 'react';
import { cn } from '@/lib/classNames';
import { Router } from '@/middleware/i18n';
import {
  useSidebarState,
} from '@/controllers/apollo/apollo.modules/sidebar/sidebar.hooks';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import styles from '@/components/platform/Sidebar/Sidebar.module.scss';
import { SidebarDropdown } from '@/components/platform/SidebarDropdown/SidebarDropdown';
import { StudentSidebarItems } from './StudentSidebarItems';

interface Props {
  shouldHideOverlay?: boolean;
}

export const Sidebar: FC<Props> = React.memo((props) => {
  const { shouldHideOverlay } = props;

  const [authUser] = useAuthUser({ ssr: true });

  const [{ sidebarOpen }, toggleSidebar] = useSidebarState();

  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      toggleSidebar({
        open: false,
      });
    });
    const onResize = () => {
      toggleSidebar({
        open: false,
      });
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [toggleSidebar]);

  if (!authUser) {
    return null;
  }

  return (
    <>
      <div
        className={cn(
          styles.sidebarOverlay,
          {
            [styles.overlayActive]: sidebarOpen,
            [styles.mobileEditor]: shouldHideOverlay,
          },
        )}
        role="button"
        tabIndex={0}
        aria-label="Sidebar overlay"
        onKeyDown={(event) => {
          if (event.key === ' ') {
            toggleSidebar({
              open: false,
            });
          }
        }}
        onClick={() => {
          toggleSidebar({
            open: !sidebarOpen,
          });
        }}
      />
      <aside className={styles.sidebarWrapper}>
        <nav
          className={cn(styles.sidebarNav, {
            [styles.activeSidebarNav]: sidebarOpen,
          })}
          data-qa='sidebar-navigation'
        >
          <div className={cn(styles.sidebarNavInner, {
            [styles.activeSidebarNavInner]: sidebarOpen,
          })}
          >
            <div className={styles.sidebarScrollContainer} data-qa="sidebar-scroll-container">
              <SidebarDropdown />

              <ul className={cn(styles.navList, 'is-scroll')}>
                <StudentSidebarItems />
              </ul>
            </div>
          </div>
        </nav>
      </aside>
    </>
  );
});
