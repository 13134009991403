import React, { type FC, useCallback } from 'react';
import { cn } from '@/lib/classNames';
import { Button } from '@/components/ui/Button';
import { useClickOutside } from '@/hooks/useClickOutside';
import { IconBurger } from '@/components/ui/icons/IconBurger';
import { IconClose } from '@/components/ui/icons/IconClose';
import { ROUTES } from '@/controllers/router/router.contants';
import { I18N_CODES } from '@/lib/constants/general';
import { ActiveLink } from '@/components/common/ActiveLink';
import { useTranslation } from '@/middleware/i18n';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { LandingSocialLinks } from '@/components/landing/LandingSocialLinks';
import {
  MobileMenuProfileLink,
} from '@/components/landing/LandingHeader/components/HeaderMobileMenu/components/MobileMenuProfileLink';
import {
  useRouteChangeCallback,
} from '@/controllers/router/router.hooks/useRouteChangeCallback';
import {
  MobileMenuCTA,
} from '@/components/landing/LandingHeader/components/HeaderMobileMenu/components/MobileMenuCTA';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import { analyticsSDK } from '@/controllers/analytics';
import { HireGraduateFormSource } from '@/controllers/analytics/generated';
import { useGetContactUsPhoneNumber } from '@/controllers/consultation/useGetContactUsPhoneNumber';
import styles from './HeaderMobileMenuDeprecated.module.scss';

export const HeaderMobileMenu: FC = () => {
  const { active, setActive, ref } = useClickOutside(false);
  const { t } = useTranslation([I18N_CODES.common]);
  const features = useFeatures();
  const ctaContext = useCtaContext();
  const isDefaultDomain = useDefaultDomain();
  const contactUsPhoneNumber = useGetContactUsPhoneNumber();

  const hireFormLink = useTranslateByDomain(
    `${I18N_CODES.common}:hire_form_link`,
  );

  const onHireFormLinkClick = useCallback(() => {
    analyticsSDK.landing.sendHireGraduateFormOpenedEvent({
      source: HireGraduateFormSource.Header,
    });
  }, []);

  const shouldShowReviewsPageLink = isDefaultDomain
    && features.isEnabled(features.ReviewsLanding);

  useRouteChangeCallback(() => {
    setActive(false);
  });

  return (
    <div
      ref={ref}
      className={cn({
        [styles.menuOpened]: active,
        [styles.menuClosed]: !active,
      })}
    >
      <Button
        className={styles.triggerButton}
        renderLeftIcon={() => (
          <span className={styles.iconWrapper}>
            <IconBurger className={styles.burgerIcon} />
            <IconClose className={styles.closeIcon} />
          </span>
        )}
        onClick={() => setActive(!active)}
        mode={Button.mode.TransparentLight}
        size={Button.size.Large}
      />

      <div className={styles.menuWrapper}>
        <div className="grid-container">
          <div className={cn('pt-20 pb-12', styles.menuItem)}>
            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.05s' } as Record<string, string>}
            >
              <ActiveLink href={ROUTES.price}>
                <Button
                  href={ROUTES.price}
                  text={t(`${I18N_CODES.common}:price_page_link`)}
                  title={t(`${I18N_CODES.common}:price_page_link`)}
                  size={Button.size.Large}
                  mode={Button.mode.TransparentLight}
                  className={cn(styles.linkButton, 'mb-4')}
                  hasFullWidth
                />
              </ActiveLink>
            </div>

            {shouldShowReviewsPageLink && (
              <div
                className={styles.menuContentItem}
                style={{ '--delay': '0.1s' } as Record<string, string>}
              >
                <ActiveLink href={ROUTES.reviews}>
                  <Button
                    href={ROUTES.reviews}
                    text={t(`${I18N_CODES.common}:reviews_page_link`)}
                    title={t(`${I18N_CODES.common}:reviews_page_link`)}
                    size={Button.size.Large}
                    mode={Button.mode.TransparentLight}
                    className={cn(styles.linkButton, 'mb-4')}
                    hasFullWidth
                  />
                </ActiveLink>
              </div>
            )}

            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.15s' } as Record<string, string>}
            >
              <ActiveLink href={ROUTES.team}>
                <Button
                  href={ROUTES.team}
                  text={t(`${I18N_CODES.common}:company_page_link`)}
                  title={t(`${I18N_CODES.common}:company_page_link`)}
                  size={Button.size.Large}
                  mode={Button.mode.TransparentLight}
                  className={styles.linkButton}
                  hasFullWidth
                />
              </ActiveLink>
            </div>

            {hireFormLink && (
              <div
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                className={styles.menuContentItem}
                style={{ '--delay': '0.2s' } as Record<string, string>}
              >
                <Button
                  href={hireFormLink}
                  text={t(`${I18N_CODES.common}:hire_page_link`)}
                  title={t(`${I18N_CODES.common}:hire_page_link`)}
                  size={Button.size.Large}
                  mode={Button.mode.TransparentLight}
                  className={styles.linkButton}
                  target='_blank'
                  hasFullWidth
                  onClick={onHireFormLinkClick}
                />
              </div>
            )}
          </div>

          <div className={cn('pt-12 pb-12 hide-for-medium', styles.menuItem)}>
            {ctaContext.isVisible && (
              <div
                className={cn(styles.menuContentItem, 'mb-4')}
                style={{ '--delay': '0.2s' } as Record<string, string>}
              >
                <MobileMenuCTA onClick={() => setActive(false)} />
              </div>
            )}

            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.25s' } as Record<string, string>}
            >
              <MobileMenuProfileLink />
            </div>
          </div>

          {contactUsPhoneNumber.shouldShowPhoneNumber && (
            <div className={cn('pt-12 pb-12 text-center', styles.menuItem, 'hide-for-large')}>
              <div
                className={cn(styles.menuContentItem)}
                style={{ '--delay': '0.3s' } as Record<string, string>}
              >
                <Button
                  href={contactUsPhoneNumber.href}
                  title={contactUsPhoneNumber.text}
                  text={contactUsPhoneNumber.text}
                  size={Button.size.Large}
                  mode={Button.mode.TransparentLight}
                  hasFullWidth
                />
              </div>
            </div>
          )}

          <div className={cn('pt-12 pb-20 text-center', styles.menuItem)}>
            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.3s' } as Record<string, string>}
            >
              <LandingSocialLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
