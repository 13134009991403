import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconQuestionRound: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 21C7.0293 21 3 16.9707 3 12C3 7.0293 7.0293 3 12 3C16.9707 3 21 7.0293 21 12C21 16.9707 16.9707 21 12 21ZM11.1 14.7V16.5H12.9V14.7H11.1ZM12.9 13.2195C13.6233 13.0015 14.2442 12.531 14.6497 11.8936C15.0552 11.2562 15.2183 10.4943 15.1093 9.74682C15.0002 8.99929 14.6263 8.31579 14.0556 7.82081C13.4849 7.32583 12.7554 7.05228 12 7.05C11.2718 7.04994 10.566 7.30214 10.0027 7.76369C9.43943 8.22525 9.05341 8.86767 8.9103 9.5817L10.6761 9.9354C10.7262 9.68469 10.8465 9.45336 11.0229 9.26833C11.1994 9.08331 11.4247 8.95219 11.6728 8.89023C11.9208 8.82828 12.1814 8.83804 12.4241 8.91837C12.6668 8.99871 12.8817 9.14631 13.0438 9.34402C13.206 9.54173 13.3086 9.7814 13.3398 10.0352C13.371 10.2889 13.3295 10.5463 13.2202 10.7774C13.1108 11.0085 12.9381 11.2038 12.7221 11.3406C12.5061 11.4774 12.2557 11.55 12 11.55C11.7613 11.55 11.5324 11.6448 11.3636 11.8136C11.1948 11.9824 11.1 12.2113 11.1 12.45V13.8H12.9V13.2195Z" fill="currentColor" />
  </BaseIcon>
);
