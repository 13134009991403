import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { colorTokens } from '../colorTokens';

export const IconTaskInProgress: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="currentColor"
    />
    <path
      d="M11.2173 4.0008V5.61427C10.2378 5.73755 9.2999 6.0852 8.47668 6.63017C7.65346 7.17515 6.96711 7.90275 6.47104 8.75633C5.97497 9.60992 5.68257 10.5665 5.6166 11.5515C5.55063 12.5366 5.71287 13.5236 6.09068 14.4357C6.4685 15.3478 7.05171 16.1604 7.79491 16.8103C8.53811 17.4602 9.42126 17.9298 10.3756 18.1826C11.33 18.4354 12.3298 18.4645 13.2972 18.2677C14.2647 18.071 15.1737 17.6536 15.9535 17.0481L17.0946 18.1893C15.6634 19.3619 13.8696 20.0016 12.0192 19.9992C7.59022 19.9992 4 16.409 4 11.98C4 7.82197 7.1652 4.40257 11.2173 4.0008ZM19.9984 12.7819C19.8433 14.3496 19.2274 15.8363 18.2285 17.0545L17.0874 15.9142C17.7943 15.0061 18.2426 13.9238 18.3849 12.7819H19.9992H19.9984ZM12.8228 4.0008C14.6631 4.18635 16.3828 5.00214 17.6907 6.31006C18.9987 7.61798 19.8145 9.33768 20 11.178H18.3857C18.2071 9.76489 17.5636 8.45129 16.5565 7.44403C15.5494 6.43677 14.2359 5.79307 12.8228 5.61427V4V4.0008Z"
      fill={colorTokens['fill-tertiary']}
    />
  </BaseIcon>
);
