import React, { type FC, useEffect, useState } from 'react';
import confetti from 'canvas-confetti';
import { ProfileAvatar } from '@/components/platform/ProfileAvatar';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { CirclesWrapper } from '@/components/platform/Payment/PaymentsDashboard/GraduatedBlock/CirclesWrapper';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './GraduatedBlock.module.scss';

export const GraduatedBlock: FC = () => {
  const { t } = useTranslation([I18N_CODES.payment]);
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const style = getComputedStyle(document.body);

    setColors([
      style.getPropertyValue('--c-icon-accent-rose'),
      style.getPropertyValue('--c-icon-accent-magenta'),
      style.getPropertyValue('--c-icon-accent-purple'),
      style.getPropertyValue('--c-icon-accent-teal'),
      style.getPropertyValue('--c-icon-accent-lime'),
    ]);
  }, []);

  const handleCelebrate = () => {
    confetti({
      particleCount: 500,
      spread: 360,
      origin: { y: 0.95, x: 0.2 }, // bottom left corner
      shapes: ['circle', 'star'],
      colors,
    });

    confetti({
      particleCount: 300,
      spread: 360,
      origin: { y: 0.05, x: 0.2 }, // top left corner
      shapes: ['circle'],
      colors,
    });

    confetti({
      particleCount: 400,
      spread: 360,
      origin: { y: 0.4, x: 0.52 }, // in the middle of the screen
      shapes: ['circle', 'star'],
      colors,
    });

    confetti({
      particleCount: 250,
      spread: 360,
      origin: { y: 0.05, x: 0.9 }, // top right corner
      shapes: ['circle', 'star'],
      colors,
    });

    confetti({
      particleCount: 500,
      spread: 360,
      origin: { y: 0.95, x: 0.9 }, // bottom right corner
      shapes: ['circle', 'star'],
      colors,
    });
  };

  return (
    <div className={styles.container} data-qa="graduated-block">
      <CirclesWrapper>
        <div className={styles.content}>
          <div className={cn(styles.avatar, 'mb-16')} data-qa="graduated-block-avatar">
            <ProfileAvatar
              size={120}
              withBadge={false}
            />
          </div>

          <h2 className={cn(styles.title, typography.platformH2, 'mb-8')} data-qa="time-to-celebrate-title">
            {t(`${I18N_CODES.payment}:time_to_celebrate.title`)}
          </h2>

          <p className={cn(styles.subtitle, typography.platformTextSecondary, 'mb-24')} data-qa="time-to-celebrate-subtitle">
            {t(`${I18N_CODES.payment}:time_to_celebrate.subtitle`)}
          </p>

          <Button
            size={ButtonSize.Large}
            mode={ButtonMode.Primary}
            data-qa="time-to-celebrate-cta"
            onClick={handleCelebrate}
            text={t(`${I18N_CODES.payment}:time_to_celebrate.cta`)}
          />
        </div>
      </CirclesWrapper>
    </div>
  );
};
