import { type QueryHookOptions, type QueryResult } from '@apollo/client';
import { type CurrenciesRate } from '@/controllers/graphql/generated';
import {
  type CurrenciesRateQuery,
  type CurrenciesRateQueryVariables,
  useCurrenciesRateQuery,
} from '@/controllers/currenciesRates/graphql/generated/currenciesRate.query.generated';

type Args = QueryHookOptions<
  CurrenciesRateQuery, CurrenciesRateQueryVariables
>;
type Result = [
  CurrenciesRate | null,
  QueryResult<CurrenciesRateQuery, CurrenciesRateQueryVariables>
];

interface UseCurrenciesRate {
  (args: Args): Result;
}

export const useCurrenciesRate: UseCurrenciesRate = (args) => {
  const queryResult = useCurrenciesRateQuery(args);

  return [
    queryResult.data?.currenciesRate ?? null,
    queryResult,
  ];
};
