import {
  useNextProofOfRevenueStatus,
} from '@/controllers/proofOfRevenue/proofOfRevenue.hooks/useNextProofOfRevenueStatus';
import {
  useNextMonthPaymentStatus,
  useUserCourseMonthPayments,
} from '@/controllers/courseMonthPayments/courseMonthPayments.hooks';
import { PaymentStatus } from '@/controllers/graphql/generated';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { PaymentTodosGenerator } from '@/components/platform/Payment/PaymentsPage/PaymentTodosGenerator';
import { useAuthUserWithProofOfRevenue } from '@/controllers/user/user.hooks/useAuthUserWithProofOfRevenue';
import { type UserJobsList } from '@/controllers/userJob/userJob.typedefs';

export const usePaymentTodosDeprecated = (userJobs: UserJobsList) => {
  const {
    nextProofOfRevenueStatus,
    loading: isNextProofOfRevenueStatusLoading,
  } = useNextProofOfRevenueStatus();
  const [
    nextMonthPaymentStatus,
    nextMonthPaymentStatusQueryResult,
  ] = useNextMonthPaymentStatus();
  const [
    userCourseMonthPayments,
    userCourseMonthPaymentsQueryResult,
  ] = useUserCourseMonthPayments();
  const [
    authUser,
    authUserQueryResult,
  ] = useAuthUser({ ssr: true });
  const [
    authUserWithPoR,
    authUserWithPoRQueryResult,
  ] = useAuthUserWithProofOfRevenue();

  const {
    nextMonthPaidOffDate,
    paymentDeadline,
    extraFeeAmount = 0,
    extraFeeCurrency,
    percentageFeeAmount = 0,
    shouldPayFee = false,
    makePaymentAvailableDate,
  } = nextMonthPaymentStatus ?? {};

  const { proofOfRevenuePeriodData } = nextProofOfRevenueStatus ?? {};

  const completedPayments = userCourseMonthPayments.filter((payment) => (
    !payment.orderItem?.order?.payment?.refundDate
    && (
      payment.orderItem?.order?.payment?.status === PaymentStatus.Accepted
      || payment.skipped
    )
  ));

  const proofsOfRevenue = authUserWithPoR?.proofsOfRevenue ?? [];
  const agreementMonths = authUser?.agreementMonths || 0;

  const paymentFeeData = percentageFeeAmount > 0 && extraFeeCurrency
    ? {
      extraFeeAmount,
      percentageFeeAmount,
      currency: extraFeeCurrency.code,
      shouldPayFee,
    }
    : undefined;

  const nextTodoPayload = {
    paymentDeadline,
    nextMonthPaidOffDate,
    makePaymentAvailableDate,
    paymentFeeData,
    proofOfRevenuePeriodData,
  };

  const TodosGenerator = new PaymentTodosGenerator({
    nextTodoPayload,
    proofsOfRevenue,
    completedPayments,
    agreementMonths,
    userJobs,
  });

  const isLoading = (
    isNextProofOfRevenueStatusLoading
    || nextMonthPaymentStatusQueryResult.loading
    || userCourseMonthPaymentsQueryResult.loading
    || authUserQueryResult.loading
    || authUserWithPoRQueryResult.loading
  );

  return {
    todos: TodosGenerator.generateTodos(),
    loading: isLoading,
  };
};
