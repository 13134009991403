import React, { type FC, memo } from 'react';
import styles from './CircleIconWrapper.module.scss';

interface Props {
  children: JSX.Element;
}

export const CircleIconWrapper: FC<Props> = memo((props) => {
  const {
    children,
  } = props;

  return (
    <div className={styles.container}>
      {children}
    </div>
  );
});
