import { PaymentsProofsOfRevenueTable } from '@/components/platform/Payment/PaymentsProofsOfRevenueTable';
import { PaymentsTable } from '@/components/platform/Payment/PaymentsTable';
import { PaymentsHistory } from '@/components/platform/Payment/PaymentsPage/Pages/HistoryPage/PaymentsHistory';
import { colorTokens } from '@/components/ui/colorTokens';
import {
  PaymentsStatisticsPage,
} from '@/components/platform/Payment/PaymentsPage/Pages/StatisticsPage/PaymentsStatisticsPage';
import { IconLock } from '@/components/ui/icons/IconLock';
import { TagColor } from '@/components/ui/Tag/Tag.typedefs';
import { IconBell } from '@/components/ui/icons/IconBell';
import { IconEmojiCrystalBall } from '@/components/ui/IconEmojies/IconEmojiCrystalBall';
import { IconTaskInProgress } from '@/components/ui/icons/IconTaskInProgress';
import { IconSuccessCheck } from '@/components/ui/icons/IconSuccessCheck';
import { PostPaidCourseProofOfRevenueStatus, TodoStatus } from '@/components/platform/Payment/PaymentsPage/typedefs';
import {
  PaymentsHomeByFeatureStatus,
} from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHomeByFeatureStatus';
import { IconError } from '@/components/ui/icons/IconError';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { type FCImage } from '@/components/ui/IconEmojies/typedefs';
import { SubDomains } from '@/constants/subdomains';

export enum PaymentTab {
  Home = 'home',
  Statistics = 'statistics',
  History = 'history',
}

export enum HistoryTab {
  Payments = 'payments',
  Declarations = 'declarations',
}

export const HISTORY_TABS_DATA = [
  { id: HistoryTab.Payments, title_code: 'nav.history_tab_title.payments' },
  { id: HistoryTab.Declarations, title_code: 'nav.history_tab_title.declarations' },
];

export const COMPONENT_BY_HISTORY_TAB = {
  [HistoryTab.Payments]: PaymentsTable,
  [HistoryTab.Declarations]: PaymentsProofsOfRevenueTable,
};

export const TABS_DATA = [
  { id: PaymentTab.Home, title_code: 'nav.tab_title.home' },
  { id: PaymentTab.Statistics, title_code: 'nav.tab_title.statistics' },
  { id: PaymentTab.History, title_code: 'nav.tab_title.history' },
];

export const COMPONENT_BY_TAB = {
  [PaymentTab.Home]: PaymentsHomeByFeatureStatus,
  [PaymentTab.Statistics]: PaymentsStatisticsPage,
  [PaymentTab.History]: PaymentsHistory,
};

export enum DeadlineStatus {
  InProgress = 'in_progress',
  Overdue = 'overdue',
  Completed = 'completed',
}

export const STATUS_TO_PROGRESS_BAR_CONFIG = {
  [DeadlineStatus.InProgress]: {
    pathColor: colorTokens['fill-inverse'],
    trailColor: colorTokens['bg-neutral-quaternary'],
  },
  [DeadlineStatus.Overdue]: {
    pathColor: colorTokens['fill-danger-bold'],
    trailColor: colorTokens['bg-danger-secondary'],
  },
  [DeadlineStatus.Completed]: {
    pathColor: undefined,
    trailColor: undefined,
  },
};

export const TAG_BY_STATUS: Record<TodoStatus, {
  IconElement: FCIcon;
  text_code: string;
  color: TagColor;
} | null> = {
  [TodoStatus.PrevTodoRequired]: {
    IconElement: IconLock,
    text_code: 'card_tag_text.prev_todo_required',
    color: TagColor.Default,
  },
  [TodoStatus.NotAvailable]: {
    IconElement: IconBell,
    text_code: 'card_tag_text.not_available',
    color: TagColor.Purple,
  },
  [TodoStatus.InProgress]: null,
  [TodoStatus.Completed]: null,
  [TodoStatus.Unemployed]: null,
};

export const ICON_BY_STATUS: Record<TodoStatus, FCIcon | FCImage> = {
  [TodoStatus.NotAvailable]: IconEmojiCrystalBall,
  [TodoStatus.InProgress]: IconTaskInProgress,
  [TodoStatus.PrevTodoRequired]: IconTaskInProgress,
  [TodoStatus.Unemployed]: IconTaskInProgress,
  [TodoStatus.Completed]: IconSuccessCheck,
};

export const TODO_STATUSES_WITH_CONTENT = [
  TodoStatus.Completed,
  TodoStatus.InProgress,
  TodoStatus.Unemployed,
];

export const FUTURE_MONTHS_TO_RENDER = 1;

export const POR_STATUS_TO_TAG_PROPS: Record<
  PostPaidCourseProofOfRevenueStatus,
  {
    color: TagColor;
    IconElement: FCIcon;
    code: string;
    tooltip_code: string;
  } | null
> = {
  [PostPaidCourseProofOfRevenueStatus.OnReview]: {
    color: TagColor.Info,
    IconElement: IconTaskInProgress,
    code: 'proof_of_revenue_status.on_review',
    tooltip_code: 'proof_of_revenue_status.tooltip_uploaded_or_on_review',
  },
  [PostPaidCourseProofOfRevenueStatus.Uploaded]: {
    color: TagColor.Info,
    IconElement: IconTaskInProgress,
    code: 'proof_of_revenue_status.uploaded',
    tooltip_code: 'proof_of_revenue_status.tooltip_uploaded_or_on_review',
  },
  [PostPaidCourseProofOfRevenueStatus.Declined]: {
    color: TagColor.Danger,
    IconElement: IconError,
    code: 'proof_of_revenue_status.declined',
    tooltip_code: 'proof_of_revenue_status.tooltip_declined_or_failed',
  },
  [PostPaidCourseProofOfRevenueStatus.Failed]: {
    color: TagColor.Danger,
    IconElement: IconError,
    code: 'proof_of_revenue_status.failed',
    tooltip_code: 'proof_of_revenue_status.tooltip_declined_or_failed',
  },
  [PostPaidCourseProofOfRevenueStatus.Accepted]: null,
  [PostPaidCourseProofOfRevenueStatus.NotCompleted]: null,
};

export const CMPDeadline = {
  Days: 20,
  Month: 1,
};

export const COURSE_STATISTICS_SLUGS: { [key: string]: string[] } = {
  [SubDomains.ua]: [
    'ui-ux',
    'qa',
    'python',
    'frontend',
    'fullstack',
    'data-analytics',
    'java',
  ],
};
