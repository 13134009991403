import React, { type FC } from 'react';
import { COMPONENT_BY_HISTORY_TAB, type HistoryTab } from '@/components/platform/Payment/PaymentsPage/constants';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { HistoryPageTabs } from '@/components/platform/Payment/PaymentsPage/Pages/HistoryPage/HistoryPageTabs';
import { cn } from '@/lib/classNames';
import { PaymentsProgressWidgets } from '@/components/platform/Payment/PaymentsPage/Common/Widgets/PaymentsProgressWidgets';
import styles from '@/components/platform/Payment/PaymentsPage/Pages/HistoryPage/PaymentsHistory.module.scss';
import { GraduatedBlock } from '@/components/platform/Payment/PaymentsDashboard/GraduatedBlock/GraduatedBlock';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { StudentStatus } from '@/controllers/graphql/generated';

export const PaymentsHistory: FC = () => {
  const { historyTab } = useRouterQuery<{ historyTab: HistoryTab }>();
  const ComponentByTab = COMPONENT_BY_HISTORY_TAB[historyTab];

  const [authUser] = useAuthUser({ ssr: true });

  if (!authUser) {
    return null;
  }

  const { studentStatus } = authUser;

  const isGraduated = studentStatus === StudentStatus.Graduated;

  return (
    <div className='p-40'>
      {isGraduated && (
        <div className={cn(styles.graduatedBlock, 'mb-24')}>
          <GraduatedBlock />
        </div>
      )}

      <div className={styles.container}>
        <PaymentsProgressWidgets />

        <div className={cn(styles.content, 'w-100')}>
          <HistoryPageTabs />

          <ComponentByTab />
        </div>
      </div>
    </div>
  );
};
