import React, { useMemo, useState } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { SegmentedPickers } from '@/components/platform/Tabs/SegmentedPickers/SegmentedPickers';
import { type TabData } from '@/components/platform/Tabs';
import { CourseType } from '@/controllers/graphql/generated';
import { useGroupedCoursesList } from '@/controllers/courses/courses.hooks/useGroupedCoursesList';
import { IconArrowRight } from '@/components/ui/icons/IconArrowRight';
import {
  FullTimeCourseFeaturesList,
  PartTimeCourseFeaturesList,
} from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown/components/CourseFeaturesList';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { ActiveLink } from '@/components/common/ActiveLink';
import { useShouldShowOnlyPrepaidCourses } from '@/components/hooks/useShouldShowOnlyPrepaidCourses.hook';
import styles from './CoursesListScreen.module.scss';

export const CoursesListScreen = () => {
  const { t } = useTranslation([I18N_CODES.home]);

  const shouldShowOnlyPrepaidCourses = useShouldShowOnlyPrepaidCourses();
  const [activeTabId, setActiveTabId] = useState(CourseType.Prepaid);

  const {
    partTimeCourses,
    fullTimeCourses,
  } = useGroupedCoursesList();

  const isPrepaid = activeTabId === CourseType.Prepaid;

  const courses = isPrepaid
    ? partTimeCourses
    : fullTimeCourses;

  const CourseFeaturesList = isPrepaid
    ? PartTimeCourseFeaturesList
    : FullTimeCourseFeaturesList;

  const tabsData: TabData[] = useMemo(() => [
    {
      id: CourseType.Prepaid,
      title: t(`${I18N_CODES.home}:prepaid_course_button`),
    },
    {
      id: CourseType.Postpaid,
      title: t(`${I18N_CODES.home}:postpaid_course_button`),
    },
  ], [t]);

  return (
    <div className="grid-container pt-16 pb-16">
      {!shouldShowOnlyPrepaidCourses && (
        <div
          className={cn('mb-16', styles.menuContentItem)}
          style={{ '--delay': '0.05s' } as Record<string, string>}
        >
          <SegmentedPickers
            fullWidth
            tabsData={tabsData}
            activeTabId={activeTabId}
            renderTab={({
              id, title, className,
            }) => (
              <p
                className={cn(className, typography.platformH4)}
                onClick={() => {
                  setActiveTabId(id);
                }}
                aria-hidden='true'
              >
                {title}
              </p>
            )}
          />
        </div>
      )}

      <div style={{ '--delay': '0.1s' } as Record<string, string>}>
        <CourseFeaturesList
          size="SMALL"
          className={cn(styles.featuresList, styles.menuContentItem, 'mb-16')}
        />
      </div>

      <div className={styles.coursesList}>
        {courses?.map((course, index) => (
          <ActiveLink
            key={course.id}
            href={ROUTES.courses(course.slug)}
            exact
          >
            <a
              style={{ '--delay': `${0.1 + 0.025 * index}s` } as Record<string, string>}
              className={cn(
                styles.menuContentItem,
                styles.courseLink,
                typography.landingH6,
              )}
              href={ROUTES.courses(course.slug)}
            >
              {course.nameShort}

              <IconArrowRight />
            </a>
          </ActiveLink>
        ))}
      </div>
    </div>
  );
};
