import React, { type FC, useCallback } from 'react';
import { JobSearchActionType } from '@/components/platform/Payment/PaymentsPage/typedefs';
import { MonthPaymentCardAction } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/MonthPaymentCardAction';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { ROUTES } from '@/controllers/router/router.contants';
import { DeadlineStatus } from '@/components/platform/Payment/PaymentsPage/constants';
import { IconCareer } from '@/components/ui/icons/IconCareer';

interface Props {
  actions: JobSearchActionType[];
}

export const JobSearchModule: FC<Props> = ({
  actions,
}) => {
  const { t } = useTranslation([I18N_CODES.payment]);
  const [authUser] = useAuthUser({ ssr: true });
  const { push } = useRouter();
  const handleAddWorkplace = () => {
    push(ROUTES.user.career);
  };

  const handleContactSupport = useCallback(() => {
    window.open(
      t(`${I18N_CODES.payment}:career_support.contact_form_link`),
      '_blank',
    );
  }, [t]);

  return (
    <>
      {actions.map((action) => {
        const props = action === JobSearchActionType.ContactSupport
          ? {
            title: t(`${I18N_CODES.payment}:career_not_filled.find_new_workplace`),
            ctaText: t(`${I18N_CODES.payment}:career_not_filled.contact_support`),
            IconCTA: IconCareer,
            handleCTAClick: handleContactSupport,
            deadlineStatus: DeadlineStatus.InProgress,
          }
          : {
            title: t(`${I18N_CODES.payment}:career_not_filled.action_title`),
            description: t(`${I18N_CODES.payment}:career_not_filled.action_description`, {
              percent: authUser?.agreementPercent,
            }),
            ctaText: t(`${I18N_CODES.payment}:career_not_filled.action_cta`),
            handleCTAClick: handleAddWorkplace,
            deadlineStatus: DeadlineStatus.InProgress,
          };

        return (
          <MonthPaymentCardAction
            {...props}
            key={props.title}
          />
        );
      })}
    </>
  );
};
