import React, { type FC, useEffect, useMemo } from 'react';
import {
  Table, TableHead, TableHeader, TableRow,
} from '@/components/ui/Table';
import { usePaymentsProofsOfRevenueTableHeaders } from '@/components/platform/Payment/PaymentsProofsOfRevenueTable/PaymentsProofsOfRevenueTable.hooks/usePaymentsProofsOfRevenueTableHeaders';
import { PaymentsProofsOfRevenueTableBody } from '@/components/platform/Payment/PaymentsProofsOfRevenueTable/PaymentsProofsOfRevenueTableBody';
import { useAuthUserWithProofOfRevenue } from '@/controllers/user/user.hooks/useAuthUserWithProofOfRevenue';
import { ChubbsPlaceholder } from '@/components/ui/ChubsPlaceholder/ChubbsPlaceholder';
import {
  ChubbsPlaceholderBackground,
  ChubbsPlaceholderType,
} from '@/components/ui/ChubsPlaceholder/chubbsPlaceholderType';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

export const PaymentsProofsOfRevenueTable: FC = () => {
  const [authUser, { refetch }] = useAuthUserWithProofOfRevenue();
  const { t } = useTranslation([I18N_CODES.payment]);

  const proofsOfRevenue = useMemo(() => (
    authUser?.proofsOfRevenue || []
  ), [authUser]);

  const headers = usePaymentsProofsOfRevenueTableHeaders();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {proofsOfRevenue.length === 0
        ? (
          <ChubbsPlaceholder
            chubbsType={ChubbsPlaceholderType.Typing}
            textPrimary={t(`${I18N_CODES.payment}:statistics.chubbs_placeholder.title`)}
            backgroundColor={ChubbsPlaceholderBackground.Info}
          />
        )
        : (
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader width={3}>{headers.date.title}</TableHeader>
                <TableHeader width={3}>{headers.period.title}</TableHeader>
                <TableHeader width={3}>{headers.files.title}</TableHeader>
                <TableHeader width={3}>{headers.status.title}</TableHeader>
              </TableRow>
            </TableHead>

            <PaymentsProofsOfRevenueTableBody
              proofsOfRevenue={proofsOfRevenue}
            />
          </Table>
        )}
    </>
  );
};
