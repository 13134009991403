import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconCircle: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <path
      d="M 1.5,7.665 a 6.165,6.165 0 1,0 12.33,0 a 6.165,6.165 0 1,0 -12.33,0"
      fill="none"
      stroke="currentColor"
    />
  </BaseIcon>
);
