import React, { type DetailedHTMLProps, type FC, type TableHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';
import styles from './Table.module.scss';

type TableElementProps = DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
>;

export type TableProps = TableElementProps & {
  dataQA?: string;
};

export const Table: FC<TableProps> = (props) => {
  const {
    children, className, dataQA, ...rest
  } = props;
  const classes = cn(className, styles.table);

  return (
    <table data-qa={dataQA} {...rest} className={classes}>
      {children}
    </table>
  );
};
