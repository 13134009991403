import { useQuery, type QueryResult } from '@apollo/client';
import { useMemo } from 'react';
import {
  nextMonthPaymentStatus,
  type NextMonthPaymentStatusQueryResult,
} from '@/controllers/courseMonthPayments/courseMonthPayments.queries/nextMonthPaymentStatus.query';
import { type NextMonthPaymentStatus } from '@/controllers/courseMonthPayments/courseMonthPayments.typedefs';

export interface NextMonthPaymentStatusHook {
  (): [
    NextMonthPaymentStatus | null,
    QueryResult<NextMonthPaymentStatusQueryResult>
  ];
}

export const useNextMonthPaymentStatus: NextMonthPaymentStatusHook = () => {
  const queryResult = useQuery<NextMonthPaymentStatusQueryResult>(
    nextMonthPaymentStatus,
  );

  const { data, loading, error } = queryResult;
  const ready = !(loading || error);

  const content = useMemo(
    () => ((ready && data)
      ? data.nextMonthPaymentStatus
      : null),
    [ready, data],
  );

  return [content, queryResult];
};
