import React, { type FC } from 'react';
import { Button } from '@/components/ui/Button';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { ROUTES } from '@/controllers/router/router.contants';
import { IconUser } from '@/components/ui/icons/IconUser';
import { HeaderProfileAuthLink } from '@/components/landing/LandingHeader/components/HeaderProfileAuthLink';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';

export const MobileMenuProfileLink: FC = () => {
  const { t } = useTranslation([I18N_CODES.common]);

  const [authUser, { loading }] = useAuthUser({ ssr: false });

  if (authUser) {
    return (
      <HeaderProfileAuthLink
        authUser={authUser}
        shouldShowTooltip
      />
    );
  }

  return (
    <Button
      mode={Button.mode.TransparentLight}
      size={Button.size.Large}
      title={t(`${I18N_CODES.common}:sign_in_page_link`)}
      text={t(`${I18N_CODES.common}:sign_in_page_link`)}
      LeftIcon={IconUser}
      href={ROUTES.auth.signIn}
      isLoading={loading}
      hasFullWidth
    />
  );
};
