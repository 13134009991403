import {
  type FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { cn } from '@/lib';
import { Modal } from '@/components/ui/Modal';
import { typography } from '@/components/ui/typography';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { timeZoneMapper } from '@/components/common/ManagerUserTimezone/helpers/timeZoneMapper';
import { ManagerUserTimezoneHarnessSelectors } from '@/components/common/ManagerUserTimezone/ManagerUserTimezone.harness';
import { analyticsSDK } from '@/controllers/analytics';
import styles from './ManagerTimeZoneModal.module.scss';

interface Props {
  timeZone: string;
  updateTimeZone: () => void;
  isTimeZoneUpdating: boolean;
  oldTimeZone?: string | null;
  onClose?: () => void;
}

export const ManagerTimeZoneModal: FC<Props> = ({
  timeZone,
  updateTimeZone,
  isTimeZoneUpdating,
  oldTimeZone,
  onClose,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const [isOpen, setIsOpen] = useState(true);

  const newTimeZone = useMemo(
    () => timeZoneMapper(timeZone),
    [timeZone],
  );

  const onRequestClose = useCallback(() => {
    setIsOpen((currentModalSate) => !currentModalSate);

    if (onClose) {
      analyticsSDK.timezone.sendNewTimezoneModalClosedEvent({
        oldTimeZone: oldTimeZone || undefined,
        newTimeZone: timeZone,
      });

      onClose();
    }
  }, [oldTimeZone, timeZone, onClose, setIsOpen]);

  const onAccept = useCallback(
    () => {
      updateTimeZone();

      analyticsSDK.timezone.sendNewTimezoneAcceptedEvent({
        oldTimeZone: oldTimeZone || undefined,
        newTimeZone: timeZone,
      });

      setIsOpen((currentModalSate) => !currentModalSate);
    },
    [oldTimeZone, timeZone, updateTimeZone],
  );

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      type={Modal.type.Info}
      onRequestClose={onRequestClose}
      renderTitle={() => (
        <h1 className={typography.platformH2} data-qa='change-time-zone-title'>
          {t(`${I18N_CODES.common}:change_time_zone.title`)}
        </h1>
      )}
      dataQa={ManagerUserTimezoneHarnessSelectors.DataQa}
    >
      <p
        className={cn(styles.withBoldText, typography.platformTextMain, 'mb-32')}
        data-qa={`${ManagerUserTimezoneHarnessSelectors.NewTimezone}-${newTimeZone}`}
        dangerouslySetInnerHTML={{
          __html: t(
            `${I18N_CODES.common}:change_time_zone.message`,
            {
              newTimeZone,
            },
          ),
        }}
      />

      <div className='flex-container align-center'>
        <Button
          className='mr-16'
          text={t(`${I18N_CODES.common}:change_time_zone.close_button`)}
          data-qa='change-time-zone-close-button'
          mode={ButtonMode.Secondary}
          size={ButtonSize.Large}
          onClick={onRequestClose}
        />

        <Button
          text={t(`${I18N_CODES.common}:change_time_zone.accept_button`)}
          data-qa='change-time-zone-accept-button'
          isLoading={isTimeZoneUpdating}
          size={ButtonSize.Large}
          onClick={onAccept}
        />
      </div>
    </Modal>
  );
};
